import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBrandById } from "../../redux/actions/brandAction";
import { getProductsByBrand } from "../../redux/actions/productsAction";
import {
  getAllVendorProductsById,
  getVendorByusername,
} from "../../redux/actions/vendorActions";

const ViewProductsByVendorHook = (vendorname) => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const getById = async () => {
    setLoading(true);
    await dispatch(getVendorByusername(vendorname));
    setLoading(false);
  };
  let limit=20;

  async function getVendorProducts(page){
   if(vendorByusername?._id) await dispatch(getAllVendorProductsById(vendorByusername._id,`limit=${limit}&page=${page}`));
  }

  useEffect(() => {
    getById();
  }, []);

 

  useEffect(() => {
    if (loading === false)  getVendorProducts(1);
      //dispatch(getAllVendorProductsById(vendorByusername._id,`limit=${limit}&page=1`));
    
  }, [loading]);

  const allproduct = useSelector(
    (state) => state.vendorReducer.getProductsByVendorId
  );

  const vendorByusername = useSelector(
    (state) => state.vendorReducer.vendorByUsername
  );

  let items = [];
  let pagination=0;

  try {
    if (allproduct)
      if (allproduct) {
        items = allproduct.data;
        pagination=allproduct.numberOfPages;
      }

      else items = [];
  } catch (error) {}

  const getPage = async (page) => {
    localStorage.setItem('page',page);
    getVendorProducts(page);

  }

  return [items, vendorByusername, loading,pagination,getPage];
};

export default ViewProductsByVendorHook;
