import Cookies from "js-cookie";
import { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import EditOfferHook from "../../hook/offers/EditOfferHook";
import formDate2 from "../Utilities/formDate2";



const EditOffer = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const startDateRef = useRef();
  const endDateRef = useRef();

  const [
    nameAr,
    nameEn,
    startDate,
    endDate,
    onChangeNameAr,
    onChangeNameEn,
    onChangeStartDate,
    onChangeEndDate,
    onSubmit,
    settingsRes,
  ] = EditOfferHook(id);

console.log(startDate)

  return (
    <div>
      <Helmet>
        <title>
          {`${t("Edit Offer")} - ${
            Cookies.get("i18next") == "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">{t("Edit Offer")}</div>
        <Col sm="8">
          <label htmlFor="nameAr" className="d-block mt-2 ">
            {t("nameAr")}{" "}
          </label>
          <input
            id="nameAr"
            onChange={onChangeNameAr}
            value={nameAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("nameAr")}
          />
          <label htmlFor="nameEn" className="d-block mt-2 ">
            {t("nameEn")}{" "}
          </label>
          <input
            id="nameEn"
            onChange={onChangeNameEn}
            value={nameEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("nameEn")}
          />
          <label htmlFor="startingdate" className="d-block mt-2 ">
            {t("starting date")}{" "}
          </label>
          <input
            id="startingdate"
            ref={startDateRef}
            onChange={onChangeStartDate}
            value={formDate2(startDate)}
            type="text"
            className="input-form d-block mt-2 px-3"
            onFocus={() => (startDateRef.current.type = "date")}
            onBlur={() => (startDateRef.current.type = "text")}
          />
          <label htmlFor="experationdate" className="d-block mt-2 ">
            {t("experation date")}{" "}
          </label>
          <input
            id="experationdate"
            ref={endDateRef}
            onChange={onChangeEndDate}
            value={formDate2(endDate)}
            type="text"
            className="input-form d-block mt-2 px-3"
            onFocus={() => (endDateRef.current.type = "date")}
            onBlur={() => (endDateRef.current.type = "text")}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            {t("Modify")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default EditOffer;
