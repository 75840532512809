import {
  CREATE_CONTACT_US_GALLERY,
  GET_ERROR,
  CONTACT_US_GALLERY_BY_ID,
  UPDATE_CONTACT_US_GALLERY,
  GET_ALL_CONTACT_US_GALLERY,
} from "../type";
const inital = {
  allContactusGallery: [],
  contactUsGallery: [],
  contactUsGalleryById: [],
  updateContactUsGallery: [],
  loading: true,
};

const contactUsGalleryReducers = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_CONTACT_US_GALLERY:
      return {
        ...state,
        allContactusGallery: action.payload,
        loading: false,
      };
    case CREATE_CONTACT_US_GALLERY:
      return {
        contactUsGallery: action.payload,
        loading: false,
      };

    case CONTACT_US_GALLERY_BY_ID:
      return {
        contactUsGalleryById: action.payload,
        loading: false,
      };
    case UPDATE_CONTACT_US_GALLERY:
      return {
        ...state,
        updateContactUsGallery: action.payload,
        loading: false,
      };

    case GET_ERROR:
      return {
        brand: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};

export default contactUsGalleryReducers;
