import { useEffect } from "react";
import { useState } from "react";
import {
  updateDropShipping,
  getOneDropShopping,
} from "../../redux/actions/dropShippingAction";
import { useDispatch, useSelector } from "react-redux";
import notify from "../useNotification";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AdminEditShippingHook = (id) => {
  const [values, setValues] = useState({
    nameAr: "",
    nameEn: "",
    profitRatio: "",
    currencyFactor: "",
    key: "",
  });
  const [loading, setLoading] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(true);

  const { t } = useTranslation();

  const onChangeValues = (event) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    dispatch(getOneDropShopping(id));
    setLoading(false);
  }, []);

  const oneDropShippingRes = useSelector(
    (state) => state.dropShippingReducer.oneDropShipping
  );

  useEffect(() => {
    if (loading === false) {
      if (oneDropShippingRes) {
        setValues({
          ...values,
          nameAr: oneDropShippingRes.nameAr,
          nameEn: oneDropShippingRes.nameEn,
          profitRatio: oneDropShippingRes.profitRatio,
          currencyFactor: oneDropShippingRes.currencyFactor,
          key: oneDropShippingRes.key,
        });
      }
    }
  }, [loading, oneDropShippingRes]);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  //edit dropshipping
  const onSubmit = async () => {
    console.log("values");
    console.log(values);

    if (
      values.nameAr === "" ||
      values.nameEn === "" ||
      values.currencyFactor === "" ||
      values.profitRatio === ""
    ) {
      return notify(t("please complete the data"), "error");
    }  

    setLoadingUpdate(true);
    await dispatch(
      updateDropShipping(id, {
        nameAr: values.nameAr,
        nameEn: values.nameEn,
        profitRatio: values.profitRatio,
        currencyFactor: values.currencyFactor,
      })
    );
    setLoadingUpdate(false);
  };

  const updateResponse = useSelector(
    (state) => state.dropShippingReducer.updateDropShippng
  );

  useEffect(() => {
    if(loadingUpdate === false){
        if(updateResponse){
            console.log('updateResponse')
            console.log(updateResponse)
            notify(t("Modified successfully"), "success");
            setTimeout(()=>{
                navigate('/admin/dropShippings')
            },1000)

        }else{
            notify(t("Failed in the editing process"), "error");
        }
    }
  }, [loadingUpdate]);

  return [values, onChangeValues, settingsRes, onSubmit];
};

export default AdminEditShippingHook;
