import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "../../redux/actions/settingsAcions";

const ProtectedRouteHook = () => {
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [isUser, setIsUser] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [isVendor, setIsVendor] = useState();
  const [firstRender, setFirstRender] = useState(true);
  //const firstRender=useRef(true);

  const dispatch = useDispatch();



  useEffect(() => {


    dispatch(getSettings());

    if (userData != null) {
      if (userData.role === "user") {
        setIsUser(true);
        setIsAdmin(false);
        setIsVendor(false);
      } else if (userData.role === "admin") {
        setIsUser(false);
        setIsVendor(false);
        setIsAdmin(true);
      } else if (userData.role === "vendor") {
        setIsVendor(true);
        setIsUser(false);
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
      setIsUser(false);
      setIsVendor(false);
    }
  }, []);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  useEffect(() => {


    // console.log(settingsRes);
    //if(firstRender.current==false) return;
    if (!firstRender) return;


    //console.log('firstRender.current',firstRender.current);

    if (settingsRes && settingsRes.data) {


      //block code tags in head
      if (settingsRes?.data?.blockSite !== '') {
        let blockStr = settingsRes?.data?.blockSite;
        let blockArr = blockStr.trim().split('</script>');
        //console.log('blockArr',blockArr);
        // console.log(blockStr.split('</script>')[2]+'</script>')

        for (let i = 0; i < blockArr.length; i++) {
          if (blockArr[i] != '') {
            var doc = new DOMParser().parseFromString(blockArr[i] + '</script>', "text/xml");
            document.getElementsByTagName("head")[0].appendChild(doc.documentElement);
          }

        }


      }

      //block codes tags at the end of body
      if (settingsRes?.data?.blockSiteInBodyEnd !== '') {
        //if(document.getElementsByTagName("body").length>=0) return;

        //1-remove script tag from blockSiteInBodyEnd
        const originalScriptText = settingsRes?.data?.blockSiteInBodyEnd;
        let blockArr = originalScriptText.trim().split('</script>');
        console.log('blockArr end', blockArr);

        for (let i = 0; i < blockArr.length; i++) {
          if (blockArr[i] != '') {
            // let doc = new DOMParser().parseFromString(blockArr[i] + '</script>', "text/xml");
            // document.body.appendChild(doc.documentElement);

            //  console.log('originalScriptText in end body');
            //  //console.log(doc);
            //  console.log('document.getElementsByTagName',document.getElementsByTagName("body"));
            //  document.body.appendChild(doc.documentElement);
            // let scriptElement = doc.firstChild;
            // console.log('scriptElement',scriptElement);
            // document.body.appendChild(scriptElement);
            // document.getElementById('root').insertAdjacentElement('afterend', scriptElement);

            let scriptElement = document.createElement('script');

            // Set the content of the script
            scriptElement.innerHTML = blockArr[i];
            document.body.appendChild(scriptElement);
          }
        }
      }

      //firstRender.current=true;
      setFirstRender(false);
    }


  }, [settingsRes]);

  return [isUser, isAdmin, isVendor, userData, settingsRes];
};

export default ProtectedRouteHook;
