import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AdminAddUserHook from "../../hook/admin/AdminAddUserHook";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const AdminAddUser = () => {
  const { t } = useTranslation();
  const [
    firstname,
    lastname,
    email,
    mobile,
    password,
    confirmPassword,
    type,
    onChangeFirstname,
    onChangeLastname,
    onChangeEmail,
    onChangeMobile,
    onChangePassword,
    onChangeConfirmPassword,
    onChangeType,
    onSubmit,
    webSiteData,
    settingsRes,
    username,
    onChangeUsername,
    usernameExists,
    checkLoading
  ] = AdminAddUserHook()

  const status = webSiteData.MULTI_VENDOR
  return (
    <div>

      <Helmet>
        <title>{`${t("add user")} - ${Cookies.get("i18next") === "ar" ? settingsRes.data?.titleAr : settingsRes.data?.titleEn}`} </title>
      </Helmet>


      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">{t("add new user")}</div>
        <Col sm="8">
          <label htmlFor="firstname" className="d-block mt-2 "> {t("first name")} </label>
          <input
            id="firstname"
            value={firstname}
            onChange={onChangeFirstname}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("first name")}
          />
          <label htmlFor="lastname" className="d-block mt-2 "> {t("last name")} </label>
          <input
            id="lastname"
            value={lastname}
            onChange={onChangeLastname}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("last name")}
          />
          <label htmlFor="email" className="d-block mt-2 ">  {t("email")}    </label>
          <input
            id="email"
            value={email}
            onChange={onChangeEmail}
            type="email"
            className="input-form d-block mt-2 px-3"
            placeholder={t("email")}
          />
          <label htmlFor="phone" className="d-block mt-2 ">  {t("phone")}    </label>
          <input
            id="phone"
            value={mobile}
            onChange={onChangeMobile}
            type="number"
            className="input-form d-block mt-2 px-3"
            placeholder={t("phone")}
          />

          <label htmlFor="type" className="d-block mt-2 ">  {t("type")}     </label>


          <select
            value={type}
            onChange={onChangeType}
            name="type"
            id="type"
            className="select input-form-area mt-2 px-3 "
          >
            <option value="0">{t("type")}</option>
            <option value="admin">{t("admin")}</option>
            <option value="user">{t("user")}</option>
            {status === "true" && <option value="vendor">{t("vendor")}</option>}
          </select>

          {
            type == "vendor" ?
              <>
                <label htmlFor="username" className="d-block mt-2 "> {t("username")} </label>
                <div className="d-flex justify-content-center align-items-center">
                  <input
                    id="username"
                    value={username}
                    onChange={onChangeUsername}
                    type="text"
                    className="input-form d-block mt-2 px-3"
                    placeholder={t("username")}
                  />
                  {checkLoading === true ? <Spinner animation="border" /> : null}
                </div>
                {
                  username != "" ?
                    usernameExists == true ? <p style={{ color: 'red' }}> {'هذا المستخدم موجود بالفعل'} </p>
                      : <p style={{ color: 'green' }}> {'هذا الاسم متاح'} </p>
                    : null
                }
              </> : null
          }
          <label htmlFor="password" className="d-block mt-2 ">{t("password")}  </label>
          <input
            id="password"
            value={password}
            onChange={onChangePassword}
            className="input-form d-block mt-2 px-3"
            placeholder={t("password")}
            type="password"
          />
          <label htmlFor="confirmPassword" className="d-block mt-2 "> {t("confirm password")}   </label>

          <input
            id="confirmPassword"
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            className="input-form d-block mt-2 px-3"
            placeholder={t("confirm password")}
            type="password"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button onClick={onSubmit} className="btn-save d-inline mt-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}>
            {t("add")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminAddUser;
