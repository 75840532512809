import React from "react";
import "./ContactUs.css"; // Import CSS for styling

const ContactUs = ({ contact_us_gallery }) => {
  return (
    <section className="contact-us">
      <div className="contact-info">
        {contact_us_gallery &&
          contact_us_gallery.map((el) => {
            return (
              <div className="contact-item">
                <img src={el.logo} alt="Location 1" />
                <p>{el.city_name_ar}</p>
                <p>{el.address}</p>
                <p>+{el.mobile}</p>
                <p>{el.email}</p>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default ContactUs;
