import React from "react";
import AdminBrands from "../../Components/Admin/AdminBrands";
import AdminSubCategories from "../../Components/Admin/AdminSubCategories";

import SideBar from "../../Components/Admin/SideBar";
import AdminContactUsGallery from "../../Components/Admin/AdminContactUsGallery";

const AdminContactUsGalleryPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
        <AdminContactUsGallery />
      </div>
    </div>
  );
};

export default AdminContactUsGalleryPage;
