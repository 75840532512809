import React, { Fragment, useEffect, useState } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { getAllSlider } from "../../redux/actions/sliderActions";

export default function SliderGallery() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true, // Disable arrows
  };
  const get = async () => {
    await dispatch(getAllSlider());
  };

  useEffect(() => {
    get();
  }, []);

  const sliders = useSelector((state) =>
    state.sliderReducer.allSlider.filter((el) => el.status === true)
  );

  return (
    <Slider {...settings} className="header-slider">
      {sliders &&
        sliders.map((el) => {
          return (
            <div className="slide" key={el._id}>
              <img src={el.images} alt="Returning to a Present" />
              <div className="slide-content">
                <h1>{el.title}</h1>
                <p>{el.description} </p>
              </div>
            </div>
          );
        })}
      {/* <div className="slide">
      <img src={slide2} alt="Artwork in Progress" />
      <div className="slide-content">
        <h1>Artwork in Progress</h1>
        <p>
          Solo Exhibition
          <br />
          15.06.2024 - 20.07.2024
        </p>
      </div>
    </div> */}
    </Slider>
  );
}
