import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminAddBrand from "../../Components/Admin/AdminAddBrand";
import AdminSideBar from "../../Components/Admin/AdminSideBar";
import SideBar from "../../Components/Admin/SideBar";
import AdminAddContactUsGallery from "../../Components/Admin/AdminAddContactUsGallery";

const AdminAddContactUsGalleryPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
        <AdminAddContactUsGallery />
      </div>
    </div>
  );
};
export default AdminAddContactUsGalleryPage;
