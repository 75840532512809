import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllcontact_gallery,
  updateContactUsGallery,
} from "../../redux/actions/contactUsGalleryAction";
import { getSettings } from "../../redux/actions/settingsAcions";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";

const AdminContactUsGalleryHook = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllcontact_gallery());
    window.scrollTo(0, 0);
  }, []);
  const brand = useSelector(
    (state) => state.contactUsGalleryReducer.allContactusGallery
  );
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateContactUsGallery(id, {
        status: status,
      })
    );
    setLoading(false);
  };
  const res = useSelector(
    (state) => state.contactUsGalleryReducer.updateContactUsGallery
  );

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllcontact_gallery());
        }, 1500);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  const clickHandler = (id) => {
    navigate(`/admin/editcontactusgallery/${id}`);
  };

  return [brand, clickHandler, handleShowFun, settingsRes];
};

export default AdminContactUsGalleryHook;
