import React from "react";
import "./ExhibitionCard.css"; // Import CSS for styling

const ExhibitionCard = ({ image, nameAr, createdAt, _id }) => {
  const obj = {
    nameAr,
    image,
    _id,
    createdAt,
  };
  const objToString = (obj) => {
    return encodeURIComponent(JSON.stringify(obj));
  };

  return (
    <a
      href={`/products/artGallery/sub_category/${_id}`} // Pass obj as URL parameter
      style={{ textDecoration: "none", color: "#000" }}
    >
      <div className="exhibition-card">
        <img src={`${image}`} alt={nameAr} />
        <div className="exhibition-info">
          <h2>{nameAr}</h2>
          <p>{createdAt}</p>
        </div>
      </div>
    </a>
  );
};

export default ExhibitionCard;
