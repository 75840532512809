import { useGetData } from "../../hooks/useGetData";
import { useInsertData } from "../../hooks/useInsertData";
import { GET_ALL_SHOPIFY_PRODUCTS , ADD_PRODUCTS_FROM_SHOPIFY , LOADING_SHOPIFY_PRODUCTS , ADDING_PRODUCTS_FROM_SHOPIFY , UPDATE_PRODUCTS_FROM_SHOPIFY
    , UPDATING_PRODUCTS_FROM_SHOPIFY } from "../type";

export const getAllShopifyProducts=(ids)=>async(dispatch)=>{
    try {

        dispatch({type:LOADING_SHOPIFY_PRODUCTS});

        const response= await useGetData(`/api/v1/shopify?ids=${ids}`);
        dispatch({
            type:GET_ALL_SHOPIFY_PRODUCTS,
            payload:response,
            // isLoading:true
        })
    } catch (error) {
        dispatch({
            type:GET_ALL_SHOPIFY_PRODUCTS,
            payload:error.message
        })
    }
};

export const addProductsFromShopify=(body)=>async(dispatch)=>{
    try {
        dispatch({type:ADDING_PRODUCTS_FROM_SHOPIFY});
        
        const response= await useInsertData('/api/v1/shopify/addProducts',body);
        dispatch({
            type:ADD_PRODUCTS_FROM_SHOPIFY,
            payload:response
        })
    } catch (error) {
        dispatch({
            type:ADD_PRODUCTS_FROM_SHOPIFY,
            payload:error
        })
    }
};

export const updateProductsFromShopify=()=>async(dispatch)=>{
    try {
        dispatch({type:UPDATING_PRODUCTS_FROM_SHOPIFY});

        const response=await useInsertData('/api/v1/shopify/updateProducts');

        dispatch({
            type: UPDATE_PRODUCTS_FROM_SHOPIFY,
            payload:response
        })

    } catch (error) {
        dispatch({
            type:UPDATE_PRODUCTS_FROM_SHOPIFY,
            payload:error
        });
    }
}