import React, { useState, useEffect } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import { t } from "i18next";
import SecondNavGallery from "../Gallary/SecondNavGallery";
import { useDispatch, useSelector } from "react-redux";
import "./MoreGallery.css";
import { Link } from "react-router-dom";
import { subCategoryByCatId } from "../../redux/actions/subCategoryAction";

const MoreGallery = () => {
  const dispatch = useDispatch();
  const subcategory = useSelector(
    (state) => state.subCategory.subCategoryByCat
  );
  const doSomething = async () => {
    await dispatch(subCategoryByCatId(null, true));
    // code here...
  };

  useEffect(() => {
    doSomething();
    return () => {};
  }, []);

  return (
    <div>
      <SecondNavGallery />
      <Container>
        <div className="ma3rads-container">
          <div className="ma3rads-title">{t("المعارض")} </div>
          <div className="ma3rads-grid">
            {subcategory &&
              subcategory.map((ma3rad, index) => (
                <Link
                  to={`/products/artGallery/sub_category/${ma3rad._id}`}
                  style={{ textDecoration: "none" }}
                  key={index}
                >
                  <div className="ma3rad-card">
                    <div className="ma3rad-image-wrapper">
                      <img
                        src={ma3rad.image}
                        alt={"name"}
                        className="ma3rad-image"
                      />
                    </div>
                    <p className="ma3rad-name">{ma3rad.nameAr}</p>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MoreGallery;
