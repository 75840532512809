import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import notify from "../useNotification";
import { createOrders } from "../../redux/actions/checkoutAction";
import UserCartHook from "../cart/UserCartHook";
import { addTransaction } from "../../redux/actions/walletAction";
import { getCheckOutStatus } from "../../redux/actions/hyperPayActions";
import { CalculateCashBackOnOrder, generateOrderObj } from "../../helpers/CalculateCashBackOnOrder";
import { payTabResponse } from "../../redux/actions/payTabActions";



const PaymentStatusHook = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const{id,resourcePath}=useParams();

    console.log(searchParams.get("id"));

    let url_string = window.location.href;
    let url = new URL(url_string);

    var user = "";
    if (localStorage.getItem("user") != null) {
        user = JSON.parse(localStorage.getItem("user"));
    }
    const [responseObject, setResponseObject] = useState({
        PaymentId: url.searchParams.get("PaymentId"),
        Result: url.searchParams.get("Result"),
        ResponseCode: url.searchParams.get("ResponseCode"),
        UserField1: url.searchParams.get("UserField1")?.split(","),
        Result: url.searchParams.get("Result"),
    });

    const [
        itemNumber,
        cartItems,
        totalPrice,
        couponNameAfterApplay,
        totalPriceAfterDiscount,
        vendorId,
        couponId,
        shippingCost
    ] = UserCartHook();
    const hyperPayRef = useRef(true);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const totalPriceData = shippingCost + Number(totalPriceAfterDiscount > 0 ? totalPriceAfterDiscount : totalPrice);

    const settingsRes = useSelector((state) => state.settingsReducers.settings);

    useEffect(() => {
        const get = async () => {
            if (responseObject.ResponseCode == '000' && responseObject.Result == 'Successful' && cartItems.length != 0) {
                setLoading(true);
                await dispatch(
                    createOrders({
                        orderItems: cartItems.map((el) => {
                            return {
                                quantity: el.quantity,
                                classification: el.classification,
                                product: el.product._id,
                                price: el.product.price1,
                                color: el.color,
                            };
                        }),
                        vendor: cartItems[0]?.product.vendor,
                        user: user._id,
                        userAddress: responseObject.UserField1[1],
                        paymentMethodId: responseObject.UserField1[0],
                        totalPrice: parseInt(totalPriceData) + parseInt(responseObject.UserField1[2]),
                        isPaid: true
                    })
                );
                // setIsSuccess(true);
                setLoading(false);
            }


            if (cartItems.length > 0) {
                //hyper pay+cashback
                if (searchParams.get("id") && searchParams.get("resourcePath") && responseObject.ResponseCode != '000') {
                    console.log('cartItems in use effect');
                    console.log(cartItems);
                    // setIsLoading(true);
                    await dispatch(getCheckOutStatus(searchParams.get("id")));

                }

            }

        };
        get();
    }, [cartItems]);

    console.log('cartItems');
    console.log(cartItems);
    const res = useSelector((state) => state.checkoutReducer.createOrder);
    const { status: hyperPayRes, isLoading: hyperPayLoading } = useSelector((state) => state.HyperPayReducer);

    useEffect(() => {
        if (loading === false) {
            if (res && res.success === true) {
                notify("تم انشاء الطلب بنجاح", "success");
                setTimeout(() => {
                    navigate("/user/allorders");
                }, 2500);
            } else {
                notify("فشل فيه اكمال الطلب", "warn");
            }
        }
    }, [loading]);

    // console.log('hyperPayRes');
    // console.log(hyperPayRes);


    useEffect(() => {

        async function createOrder() {
            let hyperPayObj = JSON.parse(localStorage.getItem('hyperpay'));
            let orderObject = generateOrderObj(cartItems, hyperPayObj.addressId, hyperPayObj.paymentId, { key: 8 });
            //  if (hyperPayObj.couponId === "") delete orderObject.coupon;

            //apply cashback
            CalculateCashBackOnOrder(totalPriceData, dispatch, settingsRes);

            await dispatch(createOrders(orderObject));


            notify("تم انشاء الطلب بنجاح", "success");
            setTimeout(() => {
                navigate("/user/allorders");
            }, 2500);

            // setLoading(false);
        }

        //000.100.1
        ///^(000.000.|000.100.1|000.[36]|000.400.[1][12]0)/
        const regex_successfull = /^(000.000.|000.100.1|000.[36]|000.400.[1][12]0)/;

        const regex_manually = /^(000.400.0[^3]|000.400.100)/;

        const regex_pending = /^(000\.200)/;

        if (hyperPayRes?.result && (regex_successfull.test(hyperPayRes?.result?.code) || regex_manually.test(hyperPayRes?.result?.code))) {
            // if (hyperPayRes?.card) {
                hyperPayRef.current = false;
                setResponseObject({ ResponseCode: '000' });
                createOrder();
            // }
            // else {
            //     setResponseObject({ ResponseCode: hyperPayRes?.result?.code });
            // }

        }
        else if (hyperPayRes?.result && (regex_pending.test(hyperPayRes?.result?.code))) {

            // if (hyperPayRes?.card) {
                hyperPayRef.current = false;
                setResponseObject({ ResponseCode: '000' });
                createOrder();
            // }
            // else {
            //     setResponseObject({ ResponseCode: hyperPayRes?.result?.code });
            // }

        }
        else {
            setResponseObject({ ResponseCode: hyperPayRes?.result?.code });
        }
    }, [hyperPayRes]);

    //paytabs
    useEffect(() => {
        const tran_ref = sessionStorage.getItem("tran_ref");
        if (tran_ref)
            dispatch(payTabResponse({ tran_ref }));
    }, []);

    const payTabRes = useSelector((state) => state.payTabReducer.paymentResponse);

    useEffect(() => {
        async function newOrder() {
            let addressId = sessionStorage.getItem("addressId");
            let orderObject = generateOrderObj(cartItems, addressId, null, { key: 9 });
            console.log('pppppppppppppppppppppp');
            await dispatch(createOrders(orderObject));
            notify("تم انشاء الطلب بنجاح", "success");
            setTimeout(() => {
                navigate("/user/allorders");
            }, 2500);
        }
        console.log('cartItems');
        console.log(cartItems);
        if (cartItems.length == 0) return;

        if (payTabRes) {
            if (payTabRes?.payment_result?.response_status == "A") {
                setResponseObject({ ResponseCode: '000' });
                CalculateCashBackOnOrder(totalPriceData, dispatch, settingsRes);
                newOrder();

            }
            else {
                //fail
                setResponseObject({ ResponseCode: '11111' });
            }
        }
    }, [payTabRes, cartItems]);

    console.log('payTabRes');
    console.log(payTabRes);

    // console.log('tran_ref');

    // console.log(sessionStorage.getItem("tran_ref"));

    // JSON.parse(sessionStorage.getItem("tran_ref"))

    return [responseObject, hyperPayLoading, isLoading];
};
export default PaymentStatusHook;
