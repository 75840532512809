import { CREATE_SUB_CATEGORY, GET_ERROR, GET_SUB_BY_CAT_ID } from "../type";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
import { useGetData } from "../../hooks/useGetData";

export const createSubCategory = (formData) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(
      `/api/v1/categories`,
      formData
    );

    dispatch({
      type: CREATE_SUB_CATEGORY,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const subCategoryByCatId = (id,status) => async (dispatch) => {
  try {
    let url=`/api/v1/categories/getSub/${id}`;
    if(status) url+=`?status=true`
    const response = await useGetData(url);
  
    dispatch({
      type: GET_SUB_BY_CAT_ID,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};
