import React from "react";
import AdminEditBrand from "../../Components/Admin/AdminEditBrand";

import SideBar from "../../Components/Admin/SideBar";
import AdminEditContactUsGallery from "../../Components/Admin/AdminEditContactUsGallery";

const AdminEditContactusGalleryPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
        <AdminEditContactUsGallery />
      </div>
    </div>
  );
};

export default AdminEditContactusGalleryPage;
