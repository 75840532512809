import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllBrand } from "../../redux/actions/brandAction";

const HomeBrandHook = () => {
  const dispatch = useDispatch();
  const [brand,setBrand] = useState([])

  useEffect(() => {
    dispatch(getAllBrand(6));
  }, [dispatch]);
  const brandres = useSelector((state) => state.allBrand.brand);
  const loading = useSelector((state) => state.allBrand.loading);


  useEffect(()=>{
if(brandres){
setBrand(brandres)
}
  },[brandres])

  // try {
  //   if (brandres)
  //     if (brandres.data) brand = brandres;
  //     else brand = [];
  // } catch (error) { }

  return [brand, loading];
};

export default HomeBrandHook;
