import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import OrderPayCashHook from "../../hook/checkout/OrderPayCashHook";
import ViewAddressHook from "../../hook/user/ViewAddressHook";
import { ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import AddAddressHook from "../../hook/user/AddAddressHook";
import { useTranslation } from "react-i18next";
import UserMapLocation from "../User/UserMapLocation";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function removeHyperPayForm() {
  console.log('on return use effect ');
  //remove prev hyperpay form from div
  const form = document.getElementById('hyperPay');
  console.log('form', form);
  if (form?.hasChildNodes()) {
    var children = form.childNodes;
    for (var i = children.length - 1; i >= 0; i--) {
      var child = children[i];
      if (child.nodeType === 1) { // Check if it's an element node
        form.removeChild(child);
      }
    }
    //form.remove();
  }
}
//import baseURL from "../../Api/baseURL";

//const baseURLString = baseURL.defaults.baseURL;

const PaymentMethod = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const prevHyperPayCheckOutRes = useRef();
  const firstRenderRef = useRef(true);

  const [res] = ViewAddressHook();
  const [
    handleChooseAddress,
    createOrderCash,
    payments,
    totalPriceData,
    handlePaymentMethods,
    selectCard, setSelectCard,
    selectCardModal, setSelectCardModal
  ] = OrderPayCashHook(parseInt(location?.state?.shippingCost) || 0);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const currencytype = Cookies.get("i18next") === "ar" ? settingsRes.data?.defaultCurrancy.ar : settingsRes.data?.defaultCurrancy.en

  // console.log('parseInt(location?.state?.shippingCost)');
  // console.log(parseInt(location?.state?.shippingCost));

  const [
    name,
    zone,
    city,
    address,
    mobile,
    closeSign,
    onChangeName,
    onChangeZone,
    onChangeCity,
    onChangeAddress,
    onChangeMobile,
    onChangeCloseSign,
    onSubmit,
    show,
    handleShow,
    handleClose,
    latitude,
    longitude,
    onChangeLatLang,
    showAddressDetails,



  ] = AddAddressHook(2);

  const [loadingSpinner, setLoadingSpinner] = useState(false);


  //  const divElement = document.getElementById('hyperPay');
  //  console.log(divElement?.firstChild);
  //const hyperPayCheckOutRes=useSelector((state)=>state.HyperPayReducer.checkOut);
  const { isLoading: hyperPayLoading, checkOut: hyperPayCheckOutRes } = useSelector((state) => state.HyperPayReducer);

  // console.log('hyperPayCheckOutRes');
  // console.log(hyperPayCheckOutRes);

  useEffect(() => {
    removeHyperPayForm();
  }, []);


  useEffect(() => {
    // if (prevHyperPayCheckOutRes.current?.id) return;
    if (firstRenderRef.current == true) {
      firstRenderRef.current = false;
      return;
    }
    if (hyperPayCheckOutRes?.id) {

      prevHyperPayCheckOutRes.current = hyperPayCheckOutRes;
      const hyperpay = payments?.find(el => el.key = 8);
      const checkOutId = hyperPayCheckOutRes.id;

      // console.log('checkOutId');
      // console.log(checkOutId);
      // console.log('hyperpay.paymentKey');
      // console.log(hyperpay?.paymentKey);



      //////////create form with action ////////////
      let form = document.createElement('form');

      //https://shelly-ksa.com/user/allorders
      //https://shelly-az.com/
      //${baseURLString}/user/allorders
      form.setAttribute('action', `https://shelly-az.com/order/paymentStatus`);
      form.setAttribute('class', "paymentWidgets");
      form.setAttribute('data-brands', `${selectCard}`);
      document.getElementById("hyperPay").appendChild(form);
      // form.submit();

      const paymentUrl = hyperpay.paymentKey;

      //https://eu-test.oppwa.com/v1/checkouts/8a82944a4cc25ebf014cc2c782423202/registration

      //const scriptTextWithoutTags = paymentUrl.replace(/<script[^>]*>/, '').replace(/<\/script>/, '');
      //const paymentUrlWithCheckout = paymentUrl.replace("{checkoutId}", `${checkOutId}/registration`);


      //test
      const paymentUrlWithCheckout = `https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkOutId}`;

      //life
      // const paymentUrlWithCheckout=`https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkOutId}`;

      console.log('paymentUrlWithCheckout');
      console.log(paymentUrlWithCheckout);
      //2-create script tag
      const script = document.createElement('script');
      
      // script.type='text/javascript';
      script.src = paymentUrlWithCheckout;
      console.log('script', script);
      // //3-append tag to body
      document.getElementById("hyperPay").appendChild(script);
      setLoadingSpinner(false);
      //  <form action="{shopperResultUrl}" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
    }

    // return ()=>{
    //   console.log('on return use effect ');
    //   //remove prev hyperpay form from div
    //   removeHyperPayForm();
    // }
  }, [hyperPayCheckOutRes]);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>

        <div style={{ width: '100%' }}>
          <Modal.Header>
            <Modal.Title>
              <div className="admin-content-text font"> {t("add new address")} </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div >
              <Row >
                <Col sm="8" style={{ width: '100%' }}>
                  <label htmlFor="address" >{t("Title name")}</label>
                  <input

                    id='address'
                    value={name}
                    onChange={onChangeName}
                    type="text"
                    className="input-form d-block mt-2 px-3"
                    placeholder={t("Title nam")}
                  />
                  {showAddressDetails ? <>
                    <label htmlFor="closeSign" className="mt-2" >{t("nearest mark")}</label>
                    <textarea
                      id="closeSign"
                      value={closeSign}
                      onChange={onChangeCloseSign}
                      className="input-form-area p-2 mt-2"
                      rows="2"
                      cols="50"
                      placeholder={t("nearest mark")}
                    />
                    <label htmlFor="phone" className="mt-2" > {t("phone number")}</label>
                    <input
                      id="phone"
                      value={mobile}
                      onChange={onChangeMobile}
                      type="text"
                      className="input-form d-block mt-2 px-3"
                      placeholder={t("phone number")}
                    />
                    <label htmlFor="zone" className="mt-2" >{t("region")}</label>
                    <input
                      id="zone"
                      value={zone}
                      onChange={onChangeZone}
                      type="text"
                      className="input-form d-block mt-2 px-3"
                      placeholder={t("region")}
                    />
                    <label htmlFor="city" className="mt-2" > {t("city")}</label>
                    <input
                      id="city"
                      value={city}
                      onChange={onChangeCity}
                      type="text"
                      className="input-form d-block mt-2  px-3"
                      placeholder={t("city")}
                    />
                  </> : ''}
                  <label htmlFor="notes" className="mt-2" > {t("Notes")}</label>
                  <textarea
                    id="notes"
                    value={address}
                    onChange={onChangeAddress}
                    className="input-form-area p-2 my-2"
                    rows="4"
                    cols="50"
                    placeholder={t("Notes")}
                  />
                  {/* <iframe
         width="100%"
         height="250"
         id="gmap_canvas"
         src={`https://maps.google.com/maps?q=${latitude},${longitude}&iwloc=&output=embed`}
         frameborder="0"
         scrolling="no"
         marginheight="0"
         marginwidth="0"
       ></iframe> */}
                  <UserMapLocation latitude={latitude} longitude={longitude} onPress={onChangeLatLang} />

                </Col>
              </Row>

              <ToastContainer />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="font d-inline mt-2"
              variant="secondary"
              onClick={handleClose}
            >
              {t("retreat")}
            </Button>
            <button onClick={onSubmit} className="btn-save d-inline mt-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}>
              {t("add adress")}
            </button>
          </Modal.Footer>
        </div>
      </Modal>

      <Modal show={selectCardModal} onHide={() => setSelectCardModal(false)} >
        <Modal.Body>
          <div>
            <input
              onChange={() => {
                setSelectCard('VISA');
              }}
              style={{ cursor: "pointer" }}
              name="group"
              id="group2"
              type="radio"
              value={'VISA'}
              className="mt-2"
            />
            <label
              className="mx-2"
              for="group2"
              style={{ cursor: "pointer" }}
            >
              VISA
            </label>
          </div>

          <div>

            <input
              onChange={() => setSelectCard('MASTER')}
              style={{ cursor: "pointer" }}
              name="group"
              id="group2"
              type="radio"
              value={'MASTER'}
              className="mt-2"
            />
            <label
              className="mx-2"
              for="group2"
              style={{ cursor: "pointer" }}
            >
              Master Card
            </label>
          </div>

          <div>
            <input
              onChange={() => setSelectCard('MADA')}
              style={{ cursor: "pointer" }}
              name="group"
              id="group3"
              type="radio"
              value={'MADA'}
              className="mt-2"
            />
            <label
              className="mx-2"
              for="group3"
              style={{ cursor: "pointer" }}
            >
              MADA
            </label>
          </div>

          <div>
            <input
              onChange={() => setSelectCard('APPLEPAY')}
              style={{ cursor: "pointer" }}
              name="group"
              id="group3"
              type="radio"
              value={'APPLEPAY'}
              className="mt-2"
            />
            <label
              className="mx-2"
              for="group3"
              style={{ cursor: "pointer" }}
            >
              APPLE PAY
            </label>
          </div>

          <div>
            <input
              onChange={() => setSelectCard('STC_PAY')}
              style={{ cursor: "pointer" }}
              name="group"
              id="group3"
              type="radio"
              value={'STC_PAY'}
              className="mt-2"
            />
            <label
              className="mx-2"
              for="group3"
              style={{ cursor: "pointer" }}
            >
              STC
            </label>
          </div>

          {/* APPLEPAY */}
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-start w-100">
            <button onClick={() => {
              setLoadingSpinner(true);
              setSelectCardModal(false);
              // //remove prev hyperpay form from div
              // const form=document.getElementById('hyperPay');
              // console.log('form',form);
              // if(form.hasChildNodes()){
              //   var children = form.childNodes;
              //   for (var i = children.length - 1; i >= 0; i--) {
              //     var child = children[i];
              //     if (child.nodeType === 1) { // Check if it's an element node
              //         form.removeChild(child);
              //     }
              // }
              //   //form.remove();
              // } 
              createOrderCash();
            }} className="btn-add-address" style={{ width: 'fit-content', padding: '10px', color: 'black', backgroundColor: settingsRes?.data?.primaryColor }}>
              اختيار
            </button>
          </div>

        </Modal.Footer>
      </Modal>

      {/* <form action="https://hyperpay.docs.oppwa.com/tutorials/integration-guide/registrationtokens" class="paymentWidgets" data-brands="VISA MASTER AMEX">
        <label>xxxxxxxxxxxxxx</label>

        <button type="submit">submit</button>
      </form> */}

      <div>
        {payments.length !== 0 ? (
          <div className="admin-content-text pt-5">{t("choose payment method")}</div>
        ) : (
          <div className="admin-content-text pt-5">{t("choose address")} </div>
        )}
        <div className="user-address-card my-3 px-3">
          {payments
            ? payments.map((el) => {
              return (
                <Row className="d-flex justify-content-between">
                  <Col xs="12" className="d-flex my-2">
                    <input
                      onChange={handlePaymentMethods}
                      style={{ cursor: "pointer" }}
                      name="group"
                      id="group2"
                      type="radio"
                      value={el._id}
                      className="mt-2"
                    />
                    <label
                      className="mx-2"
                      for="group2"
                      style={{ cursor: "pointer" }}
                    >
                      {el.nameAr}
                    </label>
                  </Col>
                </Row>
              );
            })
            : null}
          <div className="d-flex my-3 pt-3 justify-content-start ">
            <Col xs="4" className="d-flex">
              <select
                name="address"
                id="address"
                className="select mt-1 px-2 "
                onChange={handleChooseAddress}
              >
                <option value="0">{t("choose address")}</option>
                {res.length >= 1 ? (
                  res.map((el) => {
                    return (
                      <option key={el._id} value={el._id}>
                        {el.name}
                      </option>
                    );
                  })
                ) : (
                  null
                )}
              </select>
            </Col>
            <Col sm="5" className="d-flex justify-content-start mx-2">
              <button onClick={handleShow} className="btn-add-address" style={{ width: 'fit-content', padding: '10px', color: 'black', backgroundColor: settingsRes?.data?.primaryColor }}>
                {t("add new adress")}
              </button>
            </Col>
          </div>

        </div>


        <Row>
          <Col xs="12" className="pb-5 d-flex justify-content-end ">
            <div className="product-price d-inline   border">
              {Number(totalPriceData)?.toFixed(2)} {currencytype}
            </div>
            <div
              onClick={createOrderCash}
              className="product-cart-add px-3 pt-2 d-inline me-2" style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            >
              {hyperPayLoading ? <Spinner /> : t("Checkout")}
            </div>
          </Col>
        </Row>
        {loadingSpinner && <Spinner />}
        <div id="hyperPay" style={{ direction: 'ltr' }}>

        </div>

    
        {/* <button onClick={remove}>remove</button> */}
      </div>
      <ToastContainer />
    </div>
  );
};

export default PaymentMethod;


