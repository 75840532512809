import { PAYTAB_START,PAYTAB_RESPONSE } from "../type";

const inital={
    paymentUrl:null,
    paymentResponse:null
};

const payTabReducer=(state=inital,action)=>{
    switch (action.type) {
        case PAYTAB_START:
            return{
                ...state,
                paymentUrl:action.payload
            }
        case PAYTAB_RESPONSE:
            return{
                ...state,
                paymentResponse:action.payload
            }
    
        default:
            return state;
    }
}

export default payTabReducer;