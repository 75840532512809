
import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ViewAddressHook from "../../hook/user/ViewAddressHook";
import UserAddressCard from "./UserAddressCard";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const UserAllAddress = () => {
  const { t } = useTranslation()
  const [res] = ViewAddressHook();
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  return (
    <div>
      <div className="admin-content-text pb-4">{t("Address book")}</div>
      {res ? (
        res.map((item) => {
          return <UserAddressCard key={item._id} item={item} />;
        })
      ) : (
        <h6>{t("There are no addresses yet.")}</h6>
      )}

      <Row className="justify-content-center">
        <Col sm="5" className="d-flex justify-content-center">
          <Link to="/user/add-address" style={{ textDecoration: "none" }}>
            <button className="btn-add-address" style={{ backgroundColor: settingsRes?.data?.primaryColor }}>{t("add new adress")}</button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default UserAllAddress;
