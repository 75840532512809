import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import TermsPolicyAboutHook from "../../hook/termsPolicyAbout/TermsPolicyAboutHook";
import img01 from "../../Images/01.png";
import img02 from "../../Images/02.png";
import img03 from "../../Images/03.png";
import { useSelector } from "react-redux";

const TermsAboutPlicyPage = () => {
  const [t, i18n] = useTranslation();
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  const { type } = useParams();
  const [nameAr, nameEn, onChangeNameAr, onChangeNameEn, onSubmit] =
    TermsPolicyAboutHook(type);

  return (
    <div>
      <div
        style={{
          background: settingsRes?.data?.secondaryColor,
          height: "200px",
        }}
      >
        <div className="d-flex justify-content-center align-items-center h-100">
          <div
            className="admin-content-text pb-4  "
            style={{ color: settingsRes?.data?.primaryColor, fontSize: "30px" }}
          >
            {type == 1
              ? t("Terms & Conditions")
              : type == 2
              ? t("Refund Policy")
              : t("about us")}
          </div>
        </div>
      </div>
      <Container style={{ minHeight: "670px" }}>
        <Row className="py-5 d-flex justify-content-center ">
          <div className="admin-content-text pb-4">
            {type == 1
              ? t("Terms & Conditions")
              : type == 2
              ? t("Refund Policy")
              : t("about us")}
          </div>

          <Col sm="12" className="d-flex flex-column ">
            {i18n.language == "en" ? <p>{nameEn}</p> : <p>{nameAr}</p>}
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </div>
  );
};

export default TermsAboutPlicyPage;
