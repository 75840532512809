import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../../redux/actions/categoryAction";
import { createSubCategory } from "../../redux/actions/subCategoryAction";
import { getSettings } from "../../redux/actions/settingsAcions";


import avatar from "../../Images/avatar.png";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";

const AddSubCatHook = (catId) => {
  const [img, setImg] = useState(avatar);
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [id, setId] = useState(catId);
  const [loading, setLoading] = useState(true);

  const [selectedFile, setSelectedFile] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    if (!navigator.onLine) {
      notify("هنال مشكلة فالاتصال بالانترنت", "warn");
      return;
    }
    dispatch(getAllCategory(12));
  }, []);
  const category = useSelector((state) => state.allCategory.category);
  // console.log(category);
  const subcategory = useSelector((state) => state.subCategory.subCategory);

  const handleChange = (e) => {
    setId(e.target.value);
  };
  const handleSubmit = async (e) => {
    if (!navigator.onLine) {
      notify("هنال مشكلة فالاتصال بالانترنت", "warn");
      return;
    }

    e.preventDefault();
    if (id === "0" || nameAr === "" || nameEn === "" || selectedFile === null) {
      notify("من فضلك اكمل البيانات", "warn");
      return;
    }
    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    formData.append("parentId", catId);
    formData.append("image", selectedFile);
    formData.append("isChild", true);

    await dispatch(createSubCategory(formData));
    setLoading(false);
  };

  const onChangeNameAr = (e) => {
    setNameAr(e.target.value);
  };

  const onChangeNameEn = (e) => {
    setNameEn(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };
  useEffect(() => {
    if (loading === false) {
      setImg(avatar);
      setNameAr("");
      setNameEn("");
      setSelectedFile(null);
      setId("0");
      if (subcategory.status === 201) {
        notify("تمت الاصافة بنجاح", "success");
        setTimeout(()=>{
          navigate(`/admin/subcategories/${catId}`)
        },1500)
      } else {
        notify("فشل في عملية الاضافه", "error");
      }
      setLoading(true);
    }
  }, [loading]);


  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  return [
    img,
    nameAr,
    nameEn,
    category,
    handleSubmit,
    onImageChange,
    onChangeNameAr,
    onChangeNameEn,
    handleChange,
    settingsRes
  ];
};

export default AddSubCatHook;
