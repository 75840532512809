import React from "react";
import ViewProductVendorHook from "../../hook/vendor/ViewProductVendorHook";
import DataTable from "react-data-table-component";
import add from "../../Images/add.png";
import DataTableExtensions from "react-data-table-component-extensions";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { Button, Row, Spinner } from "react-bootstrap";
import AdminProductCard from "../Admin/AdminProductCard";

import AddButton from "../Utilities/AddButton";
import { useSelector } from "react-redux";
import AdminSearch from "../../Pages/Utilities/AdminSearch";
import { useTranslation } from "react-i18next";

const VendorAllProducts = () => {
  const[searchParams,setSearchParams]=useSearchParams();
  const [productsByVendorId,searchTerm,setSearchTerm,pagination] = ViewProductVendorHook();

  
  const { t } = useTranslation() ;
  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const vendorLoading=useSelector((state) => state.vendorReducer.vendorLoading);
  const [card, setCard] = useState(false);
  const navigate = useNavigate();
  const handleTable = () => {
    setCard(!card);
  };
  const columns = [
    {
      name: t("image"),
      cell: (row) => (
        <img
          src={row.image}
          width="50px"
          alt="imge of product"
          height="50px"
          style={{ padding: "3px", objectFit: "contain" }}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("product name"),
      selector: "nameAr",
      sortable: true,
    },
    {
      name: t("brand"),
      selector: (row) => row?.brand?.nameAr,
      sortable: true,
    },
    {
      name: t("category"),
      selector: (row) => row?.category?.nameAr,
      sortable: true,
    },
    {
      name: t("Subcategory"),
      selector: (row) => row?.subCategory?.nameAr,
      sortable: true,
    },

    {
      name: t("price"),
      selector: "price1",
      sortable: true,
    },
    {
      name: t("rate"),
      selector: "ratingsAverage",
      sortable: true,
    },
    {
      name: t("Number of rater"),
      selector: "ratingsQuantity",
      sortable: true,
    },
    {
      name: t("Modification"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() =>{
            localStorage.setItem("editPage",true);
            navigate(`/vendor/editProduct/${row._id}?page=${searchParams.get('page')==null? 1: searchParams.get('page')}`);
          } }
          id={row._id}
        >
        {t("Modification")}          
        <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: productsByVendorId?.data,
  };

  return (
    <div>
      <div >
        <div className="sub-tile" style={{ color: settingsRes?.data?.primaryColor }}>
          <div className="admin-content-text">{t("Manage all products")}</div>
        </div>
      
        <div className='d-flex flex-column flex-sm-row justify-content-between mt-2'>
        <AddButton title={t("add product")} navigateTo='/vendor/addproduct' />
        {vendorLoading && <Spinner />}
        <AdminSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

        </div>
        



      </div>
      {card === true ? (
        <Row className="justify-content-center">
          {productsByVendorId ? (
            productsByVendorId.map((item, index) => (
              <AdminProductCard key={index} item={item} />
            ))
          ) : (
            <h3>{t("no products")}</h3>
          )}
        </Row>
      ) : (
        <DataTableExtensions {...tableData}
        filter={false}
         >
          <DataTable
           noHeader
           defaultSortAsc={false}
           highlightOnHover
          />
        </DataTableExtensions>
      )}
    </div>
  );
};

export default VendorAllProducts;

