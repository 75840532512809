import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import PaymentStatus from '../../Components/User/PaymentStatus'
const PaymentStatusPage = () => {
    return (
        <Container >
            <Row className='py-3'>
                <Col sm="12" xs="12" md="12">
                    <PaymentStatus />
                </Col>
            </Row>
        </Container>
    )
}
export default PaymentStatusPage
