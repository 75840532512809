import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import CategoryContainer from "../../Components/Category/CategoryContainer";
import LoadingSpinner from "../../Components/Utilities/LoadingSpinner";
import Pagination from "../../Components/Utilities/Pagination";
import CatPageHook from "../../hook/category/CatPageHook";
import SubCategoryContainer from "../../Components/SubCategory/SubCategoryContainer";
import SubCategoryHook from "../../hook/category/SubCategoryHook";
import { useParams } from "react-router-dom";

const SubCategoryPage = () => {
  const { id } = useParams();

  const [subCategory, loading, loadingSpinner, colors] = SubCategoryHook(id);

  return (
    <Col md="12" sm="4">
      {loading === true ? (
        <LoadingSpinner />
      ) : (
        <SubCategoryContainer category={subCategory} loading={loading} />
      )}
    </Col>
  );
};

export default SubCategoryPage;
