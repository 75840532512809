import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllOffers,
  updateOffer,
  addOffer,
} from "../../redux/actions/offersAction";
import notify from "../useNotification";

const AllOffersHook = () => {
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLaoding] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(true);


  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  const onChangeNameAr = (e) => {
    setNameAr(e.target.value);
  };

  const onChangeNameEn = (e) => {
    setNameEn(e.target.value);
  };

  const onChangeStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const onChangeEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { t } = useTranslation();

  useEffect(() => {
    if(user?.role === 'vendor'){
      dispatch(getAllOffers(user._id));

    }else{
      dispatch(getAllOffers(''));

    }
  }, [dispatch]);

  const AllOffers = useSelector((state) => state.offersReducers.allOffers);

  //update the status Of Offer
  const handleShowFun = async (id, status) => {
    setLoadingUpdate(true);
    await dispatch(
      updateOffer(id, {
        status: status,
        type:'status'
      })
    );
    setLoadingUpdate(false);
  };

  const updateOfferStatusRes = useSelector(
    (state) => state.offersReducers.updateOffer
  );



  useEffect(() => {
    if (loadingUpdate === false) {
      if (updateOfferStatusRes) {
        notify(t("Modified successfully"), "success");
        setTimeout(() => {
          if(user?.role === 'vendor'){
            dispatch(getAllOffers(user._id));
      
          }else{
            dispatch(getAllOffers());
          }
          
        }, 1500);
      }
    }
  }, [dispatch, loadingUpdate, t, updateOfferStatusRes]);

  //get settings
  const settingsRes = useSelector((state) => state.settingsReducers.settings);


  // add Offer
  const handleSubmit = async () => {
    if (nameAr === "" || nameEn === "" || startDate === "" || endDate === "") {
      notify(t("please complete the data"), "warn");
      return;
    } else {
      setLaoding(true);
      await dispatch(
        addOffer({
          nameAr: nameAr,
          nameEn: nameEn,
          startDate: startDate,
          endDate: endDate,
          user:user._id
        })
      );
      setLaoding(false)
    }
  };

  const addOfferResponse = useSelector(state => state.offersReducers.addOffer)

  useEffect(()=>{
    if(loading === false) {
        if(addOfferResponse) {
            notify(t('Added successfully'), "success");
            setTimeout(()=>{
              if(user?.role === 'vendor'){
                navigate('/vendor/offers')
              }else {
                navigate('/admin/offers')
              }
                
            },1500)
        } else {
          notify(t("Failed in the Adding process"), "error");
        }
    }

  },[loading, addOfferResponse, t, navigate, user?.role])


  // navigate to Edit Offer Page
  const clickHandler = (id) => {
    if(user?.role === 'vendor'){
      navigate(`/vendor/editOffer/${id}`)
    }else {
      navigate(`/admin/editOffer/${id}`)
    }
  } 

  
  return [
    AllOffers,
    handleShowFun,
    settingsRes,
    nameAr,
    nameEn,
    startDate,
    endDate,
    onChangeNameAr,
    onChangeNameEn,
    onChangeStartDate,
    onChangeEndDate,
    handleSubmit,
    clickHandler,
    user
  ];
};

export default AllOffersHook;
