import Cookies from "js-cookie";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import AllOffersHook from "../../hook/offers/AllOffersHook";

const AddOffer = () => {
  const [
    ,
    ,
    settingsRes,
    nameAr,
    nameEn,
    startDate,
    endDate,
    onChangeNameAr,
    onChangeNameEn,
    onChangeStartDate,
    onChangeEndDate,
    handleSubmit
  ] = AllOffersHook();

  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>
          {`${t("add brandd")} - ${
            Cookies.get("i18next") == "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">{t("Add Offer")}</div>
        <Col sm="8">
          <label htmlFor="nameAr" className="d-block mt-2 ">
            {t("nameAr")}{" "}
          </label>
          <input
            id="nameAr"
            onChange={onChangeNameAr}
            value={nameAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("nameAr")}
          />
          <label htmlFor="nameEn" className="d-block mt-2 ">
            {t("nameEn")}{" "}
          </label>
          <input
            id="nameEn"
            onChange={onChangeNameEn}
            value={nameEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("nameEn")}
          />
          <label htmlFor="startingdate" className="d-block mt-2 ">
          {t("starting date")}{" "}
        </label>
        <input
          id="startingdate"
          onChange={onChangeStartDate}
          value={startDate}
          type="date"
          className="input-form d-block mt-2 px-3"
        />
        <label htmlFor="experationdate" className="d-block mt-2 ">
        {t("experation date")}{" "}
      </label>
      <input
        id="experationdate"
        onChange={onChangeEndDate}
        value={endDate}
        type="date"
        className="input-form d-block mt-2 px-3"
      />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={handleSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            {t("save")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AddOffer;
