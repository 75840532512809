import { GET_ALL_CATEGORY, GET_ERROR, CREATE_CATEGORY , GET_ALL_CATEGORIES , DELETE_CATEGORY , GET_ONE_CATEGORY , UPDATE_CATEGORY,GET_ALL_CATEGORIES_WITHOUT_BLOCHED_GATEGORY,GET_CATEGORIES_BY_ADMIN} from "../type";
const inital = {
  categories:[],
  category: [],
  deleteCategory: [],
  oneCategory:[],
  updateCategory:[],
  categoriesWithoutBlocked:[],
  getCategoriesByAdmin:[],
  loading: true,
};

const categoryReducer = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORY:
      return {
        ...state,
        category: action.payload,
        loading: false,
      };
    case CREATE_CATEGORY:
      return {
        ...state,
        category: action.payload,
        loading: false,
      };
    case GET_ERROR:
      return {
        ...state,
        category: action.payload,
        loading: true,
      };
      case GET_ALL_CATEGORIES:
        return{
          ...state,
          categories:action.payload
        };
        case GET_ALL_CATEGORIES_WITHOUT_BLOCHED_GATEGORY:
          return{
            ...state,
            categoriesWithoutBlocked:action.payload
          };
      case DELETE_CATEGORY:
        return {
          ...state,
          deleteCategory:action.payload
        };
      case GET_ONE_CATEGORY:
        return {
          ...state,
          oneCategory:action.payload
        };
      case UPDATE_CATEGORY:
        return {
          ...state,
          updateCategory:action.payload
        }
      case GET_CATEGORIES_BY_ADMIN:
        return{
          ...state,
          getCategoriesByAdmin:action.payload
        }
      
    default:
      return state;
  }
};

export default categoryReducer;
