import {
  GET_ALL_SHIPPING_METHODS,
  ADD_SHIPPING_METHODS,
  GET_ONE_SHIPPING_METHODS,
  EDIT_SHIPPING_METHODS,
  GET_ONE_SHIPPING_METHODS_ID
} from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useInsertData } from "../../hooks/useInsertData";
import { useUpdateData } from "../../hooks/useUpdateData";

export const getShippingMethods = () => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/shipping`);

    dispatch({
      type: GET_ALL_SHIPPING_METHODS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_SHIPPING_METHODS,
      payload: error,
    });
  }
};

// Add Payment Method
export const addShippingMehod = (body) => async (dispatch) => {

  try {
    const response = await useInsertData(`/api/v1/shipping`, body)
    dispatch({
      type: ADD_SHIPPING_METHODS,
      payload: response
    })

  }
  catch (error) {
    dispatch({
      type: ADD_SHIPPING_METHODS,
      payload: error
    })
  }
};


export const getOneShippingMehod = (id, userId) => async (dispatch) => {

  try {
    const response = await useGetDataToken(`/api/v1/shipping/${id}/${userId}`)
    // console.log(response);
    dispatch({
      type: GET_ONE_SHIPPING_METHODS,
      payload: response
    })
  } catch (error) {
    dispatch({
      type: GET_ONE_SHIPPING_METHODS,
      payload: error.response
    })
  }
}


export const editShippingMehod = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/shipping/${id}`, body);

    dispatch({
      type: EDIT_SHIPPING_METHODS,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: EDIT_SHIPPING_METHODS,
      payload: error.response,
    });
  }
}


export const getShippingByID = (id) => async (dispatch) => {

  try {
    const response = await useGetDataToken(`/api/v1/shipping/onShippingMethod/get/${id}`)
    // console.log(response);
    dispatch({
      type: GET_ONE_SHIPPING_METHODS_ID,
      payload: response
    })
  } catch (error) {
    dispatch({
      type: GET_ONE_SHIPPING_METHODS_ID,
      payload: error.response
    })
  }
}
