import React from "react";
import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const BrandCard = ({ img, id }) => {
  return (
    <Col className="my-2 d-flex justify-content-center">
      <Card
        className="brand-card-image-container"
        style={{
          width: "100%",
          borderRadius: "8px",
          border: "none",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Link to={`/products/brand/${id}`} style={{ textDecoration: "none" }}>
          <Card.Img className="brand-card-image" src={img} />
        </Link>
      </Card>
    </Col>
  );
};

export default BrandCard;
