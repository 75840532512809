
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWebsiteData, getSettings } from "../../redux/actions/settingsAcions";


const GetConfigDataHook = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSettings());
    dispatch(getWebsiteData());

  }, [])


  const webSiteData = useSelector((state) => state.settingsReducers.webSiteData);
  const settings = useSelector((state) => state.settingsReducers.settings);
  return [
    webSiteData,
    settings
  ];
};

export default GetConfigDataHook;