import React from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import AddButton from "../Utilities/AddButton";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";
import AllOffersHook from "../../hook/offers/AllOffersHook";
import formDate2 from "../Utilities/formDate2";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const AllOffers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [
    AllOffers,
    handleShowFun,
    settingsRes,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    clickHandler,
    user
  ] = AllOffersHook();

  let columns = [
    {
      name: t("nameAr"),
      selector: row => row["nameAr"]?.slice(0,30),
      sortable: true,
    },
    {
      name: t("nameEn"),
      selector: row => row["nameEn"]?.slice(0,30) ,
      sortable: true,
    },

    {
      name: t("starting date"),
      selector: (row) => formDate2(row.startDate),
      sortable: true,
    },
    {
      name: t("experation date"),
      selector: (row) => formDate2(row.endDate),
      sortable: true,
    },
    {
      name: t("details"),
      cell: (row) => (
        <div
          style={{ cursor: 'pointer' }}
          variant='secondary'
          id={row._id}
          onClick={() => user?.role === 'vendor'?(
            navigate(`/vendor/offerDetails/${row._id}`,{state:{row}}))
            :
            (navigate(`/admin/offerDetails/${row._id}`,{state:{row}}))}
        >
          {t("press here")}
          <i className="fa fa-folder-open-o mx-1" />

        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
   
    {
      name: t("Modification"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("the status"),
      cell: (row) =>
        row.status == true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
            {t("active")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const tableData = {
    columns,
    data: AllOffers,
  };

  return (
    <div>
      <Helmet>
        <title>
          {` ${t("offers")} -  ${
            Cookies.get("i18next") == "ar"
              ? settingsRes?.data?.titleAr
              : settingsRes?.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>
      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("Offers Management")} </div>
        {
          user?.role === 'vendor'?(
            <AddButton title={t("Add Offer")} navigateTo="/vendor/addOffer" />
          ):(
            <AddButton title={t("Add Offer")} navigateTo="/admin/addOffer" />
          )
        }
      </div>
        <div>
          
        </div>
      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};

export default AllOffers;

// {
//     name: t("delete"),
//     cell: (row) => (
//       <Button
//         variant="danger"
//         onClick={() => 'handleShow(row._id)'}
//         id={row._id}
//       >
//         {t("delete")}
//         <i className="fa fa-trash mx-1" />
//       </Button>
//     ),
//     ignoreRowClick: true,
//     allowOverflow: true,
//     button: true,
//   },
