import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import notify from '../../hook/useNotification';

function AddVariantsAndSkus({  tableSkuSizes,settableSkuSizes, settingsRes }) {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const[title,setTitle]=useState('');
    const[price,setPrice]=useState('');
   // const[sku,setSku]=useState('');
    const[weigth,setWeigth]=useState('');
    const[quantity,setQuantity]=useState('');
    const [link,setLink]=useState();
   // const[isDefault,setIsDefault]=useState('false');
    //console.log(setValue);
    


    const AddVariantToList = () => {
        // setValue('');
        let validate= Boolean(title=='' || price==''  || quantity=='' || weigth=='' || link=='');

        // return
         if(validate) return notify(t("please complete the data"),'warn');

         let el={
            title,price,weigth,quantity,image:link
         };

        settableSkuSizes([...tableSkuSizes,el]);
        //reset values
        setTitle('');
        setPrice('');
       // setSku('');
        setQuantity('');
        setWeigth('');
        setLink('');

        setShowModal(false);
    }
    return (
        <>
            <Modal show={showModal} onHide={() => setShowModal(false)} >
                <Modal.Header>
                    <Modal.Title>
                        <div className="font">خصائص المنتج </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label htmlFor="title" className="d-block mt-4">
                        {t("Title name")}
                    </label>
                    <input
                        id="title"
                        style={{
                            borderColor: "gray",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "6px",
                        }}
                        value={title}
                        onChange={(e)=>setTitle(e.target.value)}
                        className="d-block w-100 mt-2 p-1"
                        placeholder={t("Title name")}
                    />

                    <label htmlFor="price" className="d-block mt-4">
                        {t("price")}
                    </label>

                    <input
                        id="price"
                        type='number'
                        style={{
                            borderColor: "gray",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "6px",
                        }}
                        value={price}
                        onChange={(e)=>setPrice(e.target.value)}
                        className="d-block w-100 mt-2 p-1"
                        placeholder="price"
                    />

                    {/* <label htmlFor="sku" className="d-block mt-4">
                        {t("sku")}
                    </label>

                    <input
                        id="sku"
                        style={{
                            borderColor: "gray",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "6px",
                        }}
                        value={sku}
                        onChange={(e)=>setSku(e.target.value)}
                        className="d-block w-100 mt-2 p-1"
                        placeholder="sku"
                    /> */}

              

                    <label htmlFor="weight" className="d-block mt-4">
                        {t("weight")}
                    </label>

                    <input
                        id="weight"
                        type='number'
                        style={{
                            borderColor: "gray",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "6px",
                        }}
                        value={weigth}
                        onChange={(e)=>setWeigth(e.target.value)}
                        className="d-block w-100 mt-2 p-1"
                        placeholder="weight"
                    />

                    <label htmlFor="Quantity" className="d-block mt-4">
                        {t("Quantity")}
                    </label>

                    <input
                        id="Quantity"
                        type='number'
                        style={{
                            borderColor: "gray",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "6px",
                        }}
                        value={quantity}
                        onChange={(e)=>setQuantity(e.target.value)}
                        className="d-block w-100 mt-2 p-1"
                        placeholder="Quantity"
                    />

                    <label htmlFor="picture Link" className="d-block mt-4">
                        {t("pictureLink")}
                    </label>

                    <input
                        id="picture Link"
                        type='text'
                        style={{
                            borderColor: "gray",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "6px",
                        }}
                        value={link}
                        onChange={(e)=>setLink(e.target.value)}
                        className="d-block w-100 mt-2 p-1"
                        placeholder="picture Link"
                    />

                   

                    <Row>
                        <Col sm="12" className="d-flex justify-content-start mt-2">
                            <button
                                onClick={AddVariantToList}
                                className="btn-save d-inline mt-3"
                                style={{ backgroundColor: settingsRes?.data?.primaryColor }}
                            >
                                {t("save")}
                            </button>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
           
            <button
                onClick={() => {
                    setShowModal(true);
                    
                }}
                className="btn-save d-inline mt-2 "
                style={{ backgroundColor: settingsRes?.data?.primaryColor }}
            >
                {t("add")}
            </button>
        </>
    )
}

export default AddVariantsAndSkus