import React from "react";
import AdminAds from "../../Components/Admin/AdminAds";
import AdminBrands from "../../Components/Admin/AdminBrands";
import AdminSubCategories from "../../Components/Admin/AdminSubCategories";

import SideBar from "../../Components/Admin/SideBar";

const AdminAdsPage = () => {
    return (
        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-4" style={{ width: "100%" }}>
                <AdminAds />
            </div>
        </div>
    );
};

export default AdminAdsPage;
