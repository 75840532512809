import React, { useState, useEffect } from "react";
import notify from "../useNotification";
import { addUser } from "../../redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import { getWebsiteData, getSettings } from "../../redux/actions/settingsAcions";
import { getVendorByusername } from "../../redux/actions/vendorActions";


const AdminAddUserHook = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [type, setType] = useState("");
  const [username, setUsername] = useState("");

  const [usernameExists, setUsernameExists] = useState();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [usernameLoading, setUsernameLoading] = useState(true);

  const [checkLoading, setcheckLoading] = useState(false);


  const dispatch = useDispatch();

  
  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const webSiteData = useSelector((state) => state.settingsReducers.webSiteData);


  const onChangeUsername = async (e) => {
    e.persist();
    setUsername(e.target.value)
    setcheckLoading(true)
    setUsernameLoading(true);
    await dispatch(getVendorByusername(e.target.value));
    setUsernameLoading(false);
    setcheckLoading(false)
  };

  const vendorByusername = useSelector(
    (state) => state.vendorReducer.vendorByUsername
  );

  useEffect(() => {
    if (usernameLoading === false) {
      if (vendorByusername && vendorByusername?._id) {
        setUsernameExists(true)
      } else {
        setUsernameExists(false)
      }
    }
  }, [usernameLoading]);

  //set First Name state
  const onChangeFirstname = (e) => {
    e.persist();
    setFirstname(e.target.value);
  };
  //set Last Name state
  const onChangeLastname = (e) => {
    e.persist();
    setLastname(e.target.value);
  };
  //set email state
  const onChangeEmail = (e) => {
    e.persist();
    setEmail(e.target.value);
  };
  //set mobile state
  const onChangeMobile = (e) => {
    e.persist();
    setMobile(e.target.value);
  };
  // set Password state
  const onChangePassword = (e) => {
    e.persist();
    setPassword(e.target.value);
  };
  // set Confirm Password state
  const onChangeConfirmPassword = (e) => {
    e.persist();
    setConfirmPassword(e.target.value);
  };
  // Set type state
  const onChangeType = (e) => {
    setType(e.target.value);
  };

  const onSubmit = async () => {


    if (firstname === "") {
      notify("من فضلك ادخل الاسم الاول", "error");
      return;
    }
    if (lastname === "") {
      notify("من فضلك ادخل الاسم الاخير", "error");
      return;
    }
    if (email === "") {
      notify("من فضلك ادخل الايميل ", "error");
      return;
    }
    if (mobile === "") {
      notify("من فضلك ادخل رقم الجوال ", "error");
      return;
    }
    if (mobile.length < 9) {
      notify("من فضلك ادخل رقم الجوال صحيح", "error");
      return;
    }
    if (password === "") {
      notify("من فضلك ادخل الباسورد", "error");
      return;
    }
    if (confirmPassword === "") {
      notify("من فضلك تاكيد الباسورد   ", "error");
      return;
    }
    if (password !== confirmPassword) {
      notify("من فضلك تاكد من تطابق كلمة السر", "error");
      return;
    } if (type === "" || type === "0") {
      notify("من فضلك تأكد من اختيار النوع", "error")
      return;
    }
    if (type === "vendor" && username === "") {
      notify("من فضلك تأكد من  إضافة اسم مميز للتاجر", "error")
      return;
    }
    if (usernameExists == true) {
      notify("من فضلك تأكد من اختيار اسم المستخدم", "error")
      return;
    } else {
      await dispatch(addUser({
        firstname: firstname,
        lastname: lastname,
        mobile: mobile,
        email: email,
        password: password,
        role: type,
        username: username
      }))
      setLoading(false)
    }
  };
  const response = useSelector((state) => state.usersReducers.addUser);
  useEffect(() => {
    if (loading === false) {
      console.log('response');
      console.log(response);

      if (response?.email) {
        setFirstname("");
        setLastname("");
        setEmail("");
        setMobile("");
        setPassword("");
        setConfirmPassword("");
        setType("");
        notify("تم إضافة المستخدم بنجاح", "success");
      }
      else{
        notify("هذا المستخدم موجود بالفعل تأكد من الاميل ورقم الهاتف","error");
      }

      setLoading(true);
    }
  }, [loading]);

  return [
    firstname,
    lastname,
    email,
    mobile,
    password,
    confirmPassword,
    type,
    onChangeFirstname,
    onChangeLastname,
    onChangeEmail,
    onChangeMobile,
    onChangePassword,
    onChangeConfirmPassword,
    onChangeType,
    onSubmit,
    webSiteData,
    settingsRes,
    username,
    onChangeUsername,
    usernameExists,
    checkLoading
  ];
};

export default AdminAddUserHook;
