import React, { useState, useEffect } from "react";
import Header from "../../Components/Gallary/Header";
import ExhibitionCard from "../../Components/Gallary/ExhibitionCard";
import ArtistsCarousel from "../../Components/Gallary/ArtistsCarousel";
import AboutUs from "../../Components/Gallary/AboutUs";
import ContactUs from "../../Components/Gallary/ContactUs";
import Footer from "../../Components/Gallary/Footer";
import "./Home.css"; // Import CSS for styling
import {
  Button,
  Col,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import ButtonsGroup from "../../Components/Gallary/ButtonsGroup";
import CatPageHook from "../../hook/category/CatPageHook";
import { subCategoryByCatId } from "../../redux/actions/subCategoryAction";
import { getAllcontact_gallery } from "../../redux/actions/contactUsGalleryAction";

import { useDispatch, useSelector } from "react-redux";
import SliderGallery from "../../Components/Gallary/SliderGallery";

const Home = () => {
  const [category, loading, pageCount, getPage] = CatPageHook();

  const dispatch = useDispatch();

  const exhibitions = [
    {
      city: "جدة",
      title: "العشاء الأخير",
      dates: "10 / 05 / 2024 - 22 / 06 / 2024",
      artist: "محمد المنشاوي",
    },
    {
      city: "جدة",
      title: "زهرة الخشخاش",
      dates: "10 / 05 / 2024 - 22 / 06 / 2024",
      artist: "محمد المنشاوي ٢",
    },
    {
      city: "الرياض",
      title: "التطور الحضاري",
      dates: "10 / 05 / 2024 - 22 / 06 / 2024",
      artist: "محمد المنشاوي",
    },
    {
      city: "الرياض",
      title: "التطور الحضاري",
      dates: "10 / 05 / 2024 - 22 / 06 / 2024",
      artist: "محمد المنشاوي",
    },
    {
      city: "الرياض",
      title: "التطور الحضاري",
      dates: "10 / 05 / 2024 - 22 / 06 / 2024",
      artist: "محمد المنشاوي",
    },
    {
      city: "العلا",
      title: "التطور الحضاري",
      dates: "10 / 05 / 2024 - 22 / 06 / 2024",
      artist: "محمد المنشاوي",
    },
  ];
  const handleCategoryClick = async (categoryId) => {
    await dispatch(subCategoryByCatId(categoryId, true));
  };
  const subcategory = useSelector((state) =>
    state.subCategory.subCategoryByCat.slice(0, 4)
  );
  const contact_us_gallery = useSelector((state) =>
    state.contactUsGalleryReducer.allContactusGallery.filter(
      (el) => el.status == true
    )
  );

  const get = async () => {
    await dispatch(subCategoryByCatId(null, true));
    await dispatch(getAllcontact_gallery());
  };
  useEffect(() => {
    get();
    return () => {};
  }, []);

  return (
    <div className="home">
      <Header />
      <div className="slider-container">
        <SliderGallery />
        <div className="overlay"></div>
      </div>
      <section id="exhibitions" className="exhibitions">
        <h2>المعارض</h2>

        <div>
          <ButtonsGroup
            category={category}
            onCategoryClick={handleCategoryClick}
          />
        </div>
        <div
          className="exhibitions-grid-main"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="exhibitions-grid">
            {subcategory.map((exhibition, index) => (
              <ExhibitionCard key={index} {...exhibition} />
            ))}
          </div>
          <p style={{ display: "flex" }}>
            <a href="products/allSubCategory"> المزيد من المعارض </a>
          </p>
        </div>
      </section>
      <section className="artist" id="artists">
        <h2>الفنانين</h2>
        <ArtistsCarousel />
      </section>
      <section className="about" id="about">
        <h2>من نحن</h2>

        <AboutUs />
      </section>
      <section className="contact" id="contact">
        <h2>تواصل معنا</h2>
        <ContactUs contact_us_gallery={contact_us_gallery} />
      </section>
      <Footer contact_us_gallery={contact_us_gallery} />
    </div>
  );
};

export default Home;
