import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOnePaymentMethod, editPaymentMethod } from "../../redux/actions/paymentMethodAction"
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom"
import { editShippingMehod, getOneShippingMehod, getShippingByID } from "../../redux/actions/shippingMethodAction";

const EditShippingHook = (id) => {

    const [paymentMethod_Ar, setPaymentMethod_Ar] = useState("");
    const [paymentMethod_En, setPaymentMethod_En] = useState("");
    const [paymentMethodStatus, setPaymentMethodStatus] = useState("");

    const [wieghtNumber, setWieghtNumber] = useState("");
    const [wieghtCost, setWieghtCost] = useState("");
    const [wieghtExtraCost, setWieghtExtraCost] = useState("");

    const [loading, setLoading] = useState(true)
    const [loadingData, setLoadingData] = useState(true)
    const [cost, setCost] = useState("")
    const [tablekeysValues, settablekeysValues] = useState([]);
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const onePaymentMethod = useSelector((state) => state.shippingMethodReducer.shippingByID)

    useEffect(() => {
        const get = async () => {
            setLoadingData(true)
            await dispatch(getShippingByID(id))
            setLoadingData(false)

        }
        get()


    }, [])

    useEffect(() => {
        if (onePaymentMethod) {
            setPaymentMethod_Ar(onePaymentMethod.nameAr);
            setPaymentMethod_En(onePaymentMethod.nameEn);


            setWieghtNumber(onePaymentMethod.wieghtNumber);
            setWieghtCost(onePaymentMethod.wieghtCost);
            setWieghtExtraCost(onePaymentMethod.wieghtExtraCost);

            setPaymentMethodStatus(onePaymentMethod.status);
            setCost(onePaymentMethod.cost)
            settablekeysValues(onePaymentMethod.settingsData)
        }
    }, [loadingData])

    const onchangePaymentMethod_Ar = (e) => {
        e.persist()
        setPaymentMethod_Ar(e.target.value)
    }

    const onchangePaymentMethod_En = (e) => {
        e.persist()
        setPaymentMethod_En(e.target.value)
    }

    const onchangePaymentMethodStatus = (e) => {
        e.persist()
        setPaymentMethodStatus(e.target.value)
    }
    const onChangeCost = (e) => {
        e.persist()
        setCost(e.target.value)
    }
    const onChangeWieghtNumber = (e) => {
        e.persist()
        setWieghtNumber(e.target.value)
    }
    const onChangeWieghtCost = (e) => {
        e.persist()
        setWieghtCost(e.target.value)
    }
    const onChangeWieghtExtraCost = (e) => {
        e.persist()
        setWieghtExtraCost(e.target.value)
    }

    const onChangeKey = (e) => {
        e.persist();
        setKey(e.target.value);
    };
    const onChangeValue = (e) => {
        e.persist();
        setValue(e.target.value);
    };

    const clickHandler = () => {

        settablekeysValues([
            ...tablekeysValues,
            { key: key, value: value, count: tablekeysValues.length + 1 },
        ]);

        setKey('')
        setValue('')
    };


    const deleteElement = (count) => {
        settablekeysValues(tablekeysValues.filter(el => el.count !== count))

    }

    const onSubmit = async () => {
        if (paymentMethod_Ar === "" || paymentMethod_En === "" || wieghtNumber === "" || wieghtCost=== "" || wieghtExtraCost === "") {
            notify("من فضلك اكمل البيانات", "error");
            return
        }
        setLoading(true)
        dispatch(editShippingMehod(id, {
            nameAr: paymentMethod_Ar,
            nameEn: paymentMethod_En,
            status: paymentMethodStatus,
            wieghtNumber: wieghtNumber,
            wieghtCost: wieghtCost,
            wieghtExtraCost: wieghtExtraCost,
            cost: cost,
            settingsData: tablekeysValues
        }))
        setLoading(false)
    }

    const response = useSelector((state) => state.shippingMethodReducer.editshipping)

    useEffect(() => {
        if (loading === false) {
            if (response) {
                notify("تم التعديل بنجاح", "success");
                setTimeout(() => {
                    navigate("/admin/shippingMethod")
                }, 1000)
            } else {
                notify("فشل في عملية الاضافه", "error");

            }
        }


    }, [loading])

    return [
        paymentMethod_Ar,
        paymentMethod_En,
        paymentMethodStatus,
        onchangePaymentMethod_Ar,
        onchangePaymentMethod_En,
        onchangePaymentMethodStatus,
        onSubmit,
        cost,
        onChangeCost,
        tablekeysValues,
        deleteElement,
        key,
        onChangeKey,
        value,
        onChangeValue,
        clickHandler,
        show,
        handleShow,
        wieghtNumber,
        onChangeWieghtNumber,
        wieghtCost,
        onChangeWieghtCost,
        wieghtExtraCost,
        onChangeWieghtExtraCost,

    ]


}

export default EditShippingHook