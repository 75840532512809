import React ,{useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import {getOnePaymentMethod , editPaymentMethod} from "../../redux/actions/paymentMethodAction"
import notify from "../../hook/useNotification";
import {useNavigate} from "react-router-dom"

const EditPaymentMethodHook = (id)=>{

    const [paymentMethod_Ar,setPaymentMethod_Ar] = useState("");
    const [paymentMethod_En,setPaymentMethod_En] = useState("");
    const [paymentMethodStatus,setPaymentMethodStatus] = useState("");
    const [loading,setLoading] = useState(true);
    const [loadingData,setLoadingData] = useState(true);
    const [paymentToken,setPaymentToken] = useState("");

    const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [tablekeysValues, settablekeysValues] = useState([]);

  const onChangePaymentToken = (e) =>{
    e.persist();
    //console.log(e.target.value)
    setPaymentToken(e.target.value);
  }

  const onChangeKey = (e) => {
    e.persist();
    setKey(e.target.value);
  };
  const onChangeValue = (e) => {
    e.persist();
    setValue(e.target.value);
  };


  const clickHandler = () => {

    settablekeysValues([
      ...tablekeysValues,
      { key: key, value: value, count: tablekeysValues.length + 1 },
    ]);

    setKey('')
    setValue('')
  };

  const deleteElement = (count) => {
    settablekeysValues(tablekeysValues.filter(el => el.count !== count))

  }

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const onePaymentMethod = useSelector((state)=>state.paymentMethodReducer.onePaymentMethod)

    useEffect(()=>{
        const get = async ()=>{
            setLoadingData(true)
            await dispatch(getOnePaymentMethod(id))
            setLoadingData(false)
         
        }
        get()


    },[])

    useEffect(()=>{
        if(onePaymentMethod){
            setPaymentMethod_Ar(onePaymentMethod.nameAr);
            setPaymentMethod_En(onePaymentMethod.nameEn);
            setPaymentMethodStatus(onePaymentMethod.status);
            settablekeysValues(onePaymentMethod.banks);
            setPaymentToken(onePaymentMethod.paymentKey);


        }
    },[loadingData])

const onchangePaymentMethod_Ar =(e)=>{
    e.persist()
    setPaymentMethod_Ar(e.target.value)
}

const onchangePaymentMethod_En = (e)=>{
    e.persist()
    setPaymentMethod_En(e.target.value)
}

const onchangePaymentMethodStatus = (e) => {
    e.persist()
    setPaymentMethodStatus(e.target.value)
}

const onSubmit = async ()=>{
    if(paymentMethod_Ar ==="" || paymentMethod_En ==="" ){
        notify("من فضلك اكمل البيانات", "error");
        return
    }

     //console.log('tablekeysValues');
     //console.log(tablekeysValues)

    setLoading(true)
   await dispatch(editPaymentMethod(id,{
        nameAr:paymentMethod_Ar,
        nameEn:paymentMethod_En,
        banks:tablekeysValues,
        paymentKey:paymentToken,
        status:paymentMethodStatus
        
    }))
    setLoading(false)
}

const response = useSelector((state)=> state.paymentMethodReducer.editPaymentMethod);
//console.log(response)

useEffect(()=>{
if(loading === false){
    if(response){
        notify("تم التعديل بنجاح", "success");
        setTimeout(()=>{
            navigate("/admin/paymethoudAdmin")
        },1000)
    }else {
        notify("فشل في عملية الاضافه", "error");

    }
}


},[loading])

return [
    paymentMethod_Ar,
    paymentMethod_En,
    paymentMethodStatus,
    onchangePaymentMethod_Ar,
    onchangePaymentMethod_En,
    onchangePaymentMethodStatus,
    show,
    handleShow,
    deleteElement,
    key,
    onChangeKey,
    value,
    onChangeValue,
    clickHandler,
    tablekeysValues,
    paymentToken,
    onChangePaymentToken,
    onSubmit
]


}

export default EditPaymentMethodHook