import React, { useState , useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useDispatch ,useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import {addPaymentMethod , editPaymentMethod, getPaymentMethodsByAdmin} from "../../redux/actions/paymentMethodAction"
import { getSettings} from "../../redux/actions/settingsAcions";
import { useNavigate } from "react-router-dom";


const AddPaymentMethodHook = () => {
  const [paymentMethodName_Ar, setPaymentMethodName_Ar] = useState("");
  const [paymentMethodName_En, setPaymentMethodName_En] = useState("");
//   const [paymentMethodStatus, setpaymentMethodStatus] = useState("");
  const [loading,setLoading] = useState(true);
  const [loadingUpdate,setLoadingUpdate] = useState(true)

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const onChangePayMethodName_Ar = (e)=>{
    e.persist();
    setPaymentMethodName_Ar(e.target.value)
  }
  const onChangePayMethodName_En = (e)=>{
    e.persist();
    setPaymentMethodName_En(e.target.value)
  }

  const onSubmit = async () => {

    if(paymentMethodName_Ar === "" || paymentMethodName_En === ""){
        notify("من فضلك اكمل البيانات", "error");
        return
    }
    setLoading(true)
    await dispatch(
        addPaymentMethod({
            nameAr:paymentMethodName_Ar,
            nameEn:paymentMethodName_En
        })
    )
    setLoading(false)
  }

 const response = useSelector((state)=>state.paymentMethodReducer.addPaymentMethod)

useEffect(()=>{
    if(loading === false){
        if(response){
            setPaymentMethodName_Ar("")
            setPaymentMethodName_En("")
            notify("تم الحفظ بنجاح", "success");
            setTimeout(() => {
              window.location.reload(false);
            }, 1000);
        }else {
            notify("فشل في عملية الاضافه", "error");

        }
    }
},[loading])

const settingsRes = useSelector((state) => state.settingsReducers.settings);
const allPaymentMethods = useSelector((state)=>state.paymentMethodReducer.paymentMethods)
useEffect(()=>{
const get = async ()=>{
    await dispatch(getPaymentMethodsByAdmin())
}
get()
},[])


const clickHandler = (id) => {
  navigate(`/admin/editPaymentMethod/${id}`);
}



const handleShowFun = async (id, status) => {
  setLoadingUpdate(true);
  await dispatch(
    editPaymentMethod(id, {
      status: status,
    })
  );
  setLoadingUpdate(false);
};

useEffect(() => {
  if (loadingUpdate === false) {
      notify("تم التعديل بنجاح", "success");
      setTimeout(() => {
        dispatch(getPaymentMethodsByAdmin())
      }, 1000);
  }
}, [loadingUpdate]);





  return [
    paymentMethodName_Ar,
    paymentMethodName_En,
    onChangePayMethodName_Ar,
    onChangePayMethodName_En,
    allPaymentMethods,
    onSubmit,
    settingsRes,
    clickHandler,
    handleShowFun
  ];
};

export default AddPaymentMethodHook;
