import { t } from 'i18next'
import React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

const UserSideBar = () => {
    const settingsRes = useSelector((state) => state.settingsReducers.settings);
    const [hover1, setHover1] = useState(false);
    const handleMouseEnter1 = () => {
        setHover1(true);
    };
    const handleMouseLeave1 = () => {
        setHover1(false);
    };
    const [hover2, setHover2] = useState(false);
    const handleMouseEnter2 = () => {
        setHover2(true);
    };
    const handleMouseLeave2 = () => {
        setHover2(false);
    };
    const [hover3, setHover3] = useState(false);
    const handleMouseEnter3 = () => {
        setHover3(true);
    };
    const handleMouseLeave3 = () => {
        setHover3(false);
    };
    const [hover4, setHover4] = useState(false);
    const handleMouseEnter4 = () => {
        setHover4(true);
    };
    const handleMouseLeave4 = () => {
        setHover4(false);
    };
    const [hover5, setHover5] = useState(false);
    const handleMouseEnter5 = () => {
        setHover5(true);
    };
    const handleMouseLeave5 = () => {
        setHover5(false);
    };

    return (
        <div className="sidebar">
            <div className="d-flex flex-column">
                <Link to="/user/allorders" style={{ textDecoration: 'none' }}>
                    <div className="admin-side-text mt-3 border-bottom p-2 mx-auto text-center"
                        style={{
                            backgroundColor: hover1 ? settingsRes?.data?.primaryColor : '',
                        }}
                        onMouseEnter={handleMouseEnter1}
                        onMouseLeave={handleMouseLeave1}
                    >
                        {t("Application Management")}
                    </div>
                </Link>
                <Link to="/user/favoriteproducts" style={{ textDecoration: 'none' }}>
                    <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center"
                        style={{
                            backgroundColor: hover2 ? settingsRes?.data?.primaryColor : '',
                        }}
                        onMouseEnter={handleMouseEnter2}
                        onMouseLeave={handleMouseLeave2}
                    >
                        {t("Favorite Products")}
                    </div>
                </Link>
                <Link to="/user/addresses" style={{ textDecoration: 'none' }}>
                    <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center"
                        style={{
                            backgroundColor: hover3 ? settingsRes?.data?.primaryColor : '',
                        }}
                        onMouseEnter={handleMouseEnter3}
                        onMouseLeave={handleMouseLeave3}
                    >
                        {t("Personal Addresses")}
                    </div>
                </Link>

                <Link to="/user/profile" style={{ textDecoration: 'none' }}>
                    <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center"
                        style={{
                            backgroundColor: hover4 ? settingsRes?.data?.primaryColor : '',
                        }}
                        onMouseEnter={handleMouseEnter4}
                        onMouseLeave={handleMouseLeave4}
                    >
                        {t("Profile")}
                    </div>
                </Link>
                <Link to="/user/wallet" style={{ textDecoration: 'none' }}>
                    <div className="admin-side-text my-1 border-bottom p-2 mx-auto text-center"
                        style={{
                            backgroundColor: hover5 ? settingsRes?.data?.primaryColor : '',
                        }}
                        onMouseEnter={handleMouseEnter5}
                        onMouseLeave={handleMouseLeave5}
                    >
                        {t("Wallet")}
                    </div>
                </Link>


            </div>
        </div>
    )
}
export default UserSideBar
