import {
  CREATE_CONTACT_US_GALLERY,
  GET_ERROR,
  CONTACT_US_GALLERY_BY_ID,
  UPDATE_CONTACT_US_GALLERY,
  GET_ALL_CONTACT_US_GALLERY,
} from "../type";
import { useGetData } from "../../hooks/useGetData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";

export const getAllcontact_gallery = () => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/contact_gallery`);

    dispatch({
      type: GET_ALL_CONTACT_US_GALLERY,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CONTACT_US_GALLERY,
      payload: error,
    });
  }
};

export const getContactUsGalleryById = (id) => async (dispatch) => {
  try {
    const response = await useGetData(`/api/v1/contact_gallery/${id}`);

    dispatch({
      type: CONTACT_US_GALLERY_BY_ID,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const createContactUsGallery = (formData) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(
      `/api/v1/contact_gallery`,
      formData
    );

    dispatch({
      type: CREATE_CONTACT_US_GALLERY,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

export const updateContactUsGallery = (id, formData) => async (dispatch) => {
  try {
    const response = await useUpdateDataWithImage(
      `/api/v1/contact_gallery/${id}`,
      formData
    );
    dispatch({
      type: UPDATE_CONTACT_US_GALLERY,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CONTACT_US_GALLERY,
      payload: error.response,
    });
  }
};
