import React, { useEffect, useState } from "react";
import "./Footer.css"; // Import CSS for styling
import { Col, Row } from "react-bootstrap";
import { addContact } from "../../redux/actions/contactUsAction";
import notify from "../../hook/useNotification";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

const Footer = ({ contact_us_gallery }) => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [message, setmessage] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const onChangename = (e) => {
    setname(e.target.value);
  };
  const onChaneemail = (e) => {
    setemail(e.target.value);
  };
  const onChangephone = (e) => {
    setphone(e.target.value);
  };
  const onChangemessage = (e) => {
    setmessage(e.target.value);
  };
  const handleSubmit = async (event) => {
    if (phone === "" || email === "" || message === "" || name === "") {
      notify("من فضلك اكمل البيانات", "warn");

      return;
    }
    event.preventDefault();
    setLoading(true);
    await dispatch(
      addContact({
        name: name,
        mobile: phone,
        msgtitle: message,
        msgtext: message,
        contactType: 4,
      })
    );
    setLoading(false);
  };

  const res = useSelector((state) => state.contactUsReducer.addTicket);
  useEffect(() => {
    if (loading === false) {
      if (res) {
        setname("");
        setphone("");
        setmessage("");
        setemail("");
        notify("تم الإرسال بنجاح", "success");
      }
    } else {
    }
  }, [loading]);
  return (
    <footer className="footer-122">
      <div className="form">
        <input
          type="text"
          name="name"
          onChange={onChangename}
          value={name}
          placeholder="الاسم"
        />
        <input
          type="email"
          name="email"
          onChange={onChaneemail}
          value={email}
          placeholder="البريد الالكتروني"
        />
        <input
          type="text"
          name="phone"
          onChange={onChangephone}
          value={phone}
          placeholder="رقم الجوال"
        />
        <textarea
          name="message"
          rows={5}
          onChange={onChangemessage}
          value={message}
          placeholder="رسالتك"
        ></textarea>
        <button type="submit" onClick={handleSubmit} style={{ color: "black" }}>
          ارسال
        </button>
      </div>
      <Row className="footer-contacts">
        {contact_us_gallery &&
          contact_us_gallery.map((el) => {
            return (
              <Col className="contact-item">
                <h3>{el.city_name_ar}</h3>
                <p>{el.address} </p>
                <p>{el.mobile}</p>
                <p>{el.email}</p>
              </Col>
            );
          })}
      </Row>
      <ToastContainer />
    </footer>
  );
};

export default Footer;
