import { useDispatch, useSelector } from "react-redux";
import { addTransaction } from "../redux/actions/walletAction";

let user = JSON.parse(localStorage.getItem("user"));

// export const CalculateCashBackOnOrder=()=>{
  
// }

export async function  CalculateCashBackOnOrder(totalPriceData,dispatch,settingsRes) {
  console.log('cash hook');
    // const dispatch = useDispatch();
    // const settingsRes = useSelector((state) => state.settingsReducers.settings);
    
    let cashBackPrice;
    let cashBack=settingsRes?.data?.cashBack;
    // cash back with precentage
    if(cashBack!='0' && cashBack.includes('%')){
      cashBack= cashBack.replace(/%/g, "");
      cashBackPrice=totalPriceData*(cashBack/100);
      console.log('cashBack with precentage..........');
      console.log(cashBackPrice);
    } 

    //cash back with value
   else if(cashBack!='0' && !cashBack.includes('%')){
      console.log('cash back with value');
      console.log(cashBack);

      cashBackPrice=cashBack;
    }

    //record ايداع
    if(cashBack!='0')
    await dispatch(
      addTransaction({
        price:cashBackPrice,
        type:"1",
        user: user._id,
        addedBy: user._id
      })
    );

    return null;
}

export function generateOrderObj(cartItems,addressId=null,paymentId=null,findPaymentObj) {

  let orderObject = {
    orderItems: cartItems.map((el) => {
      return {
        quantity: el?.quantity,
        classification: el?.classification,
        productNotes: el?.productNotes,
        product: el?.product,
        price: el?.product.hasVariant==true ? el.variant.price  :el.product.price1,
        color: el?.color,
        vendor:el?.product.vendor._id,
        variant:el?.variant,
        product_weight:el?.product.weight,
        coupon: el?.couponId,
       // priceAfter:el.priceAfter>0?el.priceAfter:0,
       // quantity:el.quantity
      };
    }),
    //vendor: cartItems.product.vendor._id,
   // coupon: couponId,
    user: user._id,
    userAddress: addressId,
    paymentMethodId: paymentId,
    
   // totalPrice: totalPriceData + shippingPrice,
    isPaid: (findPaymentObj.key === 4 ||findPaymentObj.key === 8 || findPaymentObj.key === 9) ? true : false,
  };

  return orderObject;
}