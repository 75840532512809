import React from "react";

import "./AboutUs.css"; // Import CSS for styling
import Carousel from "react-bootstrap/Carousel";

import hana from "../../Images/five.jpeg";
import { Col, Row } from "react-bootstrap";
import TermsPolicyAboutHook from "../../hook/termsPolicyAbout/TermsPolicyAboutHook";

const AboutUs = () => {
  const [nameAr, nameEn, onChangeNameAr, onChangeNameEn, onSubmit, , images] =
    TermsPolicyAboutHook(3);
  console.log("________________________", images);
  return (
    <Row className="about-container">
      <Col className="about-slider-container">
        <Carousel>
          {images &&
            images.map((item) => {
              return (
                <Carousel.Item>
                  <img
                    src={item}
                    width="100%"
                    height="400px"
                    style={{ objectFit: "cover" }}
                  />
                </Carousel.Item>
              );
            })}
        </Carousel>
      </Col>
      <Col className="paragraph-container">
        <p style={{ padding: "20px", textAlign: "right" }}>
          {nameAr.substring(0, 500)}
        </p>
      </Col>
    </Row>
  );
};

export default AboutUs;
