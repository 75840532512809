import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import avatar from "../../Images/avatar.png";
import AddBrandHook from "../../hook/brand/AddBrandHook";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import AdminAddContactusGalleryHook from "../../hook/admin/AdminAddContactusGalleryHook";
const AdminAddContactUsGallery = () => {
  const { t } = useTranslation();
  const [
    img,
    nameAr,
    nameEn,
    isPress,
    loading,
    handleSubmit,
    onImageChange,
    onChangeNameAr,
    onChangeNameEn,
    settingsRes,
    email,
    onChangeemail,
    address,
    onChangeaddress,
    mobile,
    onChangemobile,
  ] = AdminAddContactusGalleryHook();

  return (
    <div>
      <Helmet>
        <title>
          {`${t("add contact us gallery")} - ${
            Cookies.get("i18next") === "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">{t("add brand")}</div>
        <Col sm="8">
          <div className="text-form pb-2">{t("brand image")}</div>
          {/* <img src={avatar} alt="" height="100px" width="120px" /> */}
          <div>
            <label htmlFor="upload-photo">
              <img
                src={img}
                alt="fzx"
                height="100px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo"
              onChange={onImageChange}
              id="upload-photo"
            />
          </div>
          <label htmlFor="caontactAr" className="d-block mt-2 ">
            {t("contact us name in arabic")}{" "}
          </label>
          <input
            id="caontactAr"
            onChange={onChangeNameAr}
            value={nameAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("contact us name in arabic")}
          />
          <label htmlFor="caontactEn" className="d-block mt-2 ">
            {t("contact us name in english")}{" "}
          </label>
          <input
            id="caontactEn"
            onChange={onChangeNameEn}
            value={nameEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("contact us name in english")}
          />
          <label htmlFor="address" className="d-block mt-2 ">
            {t("address")}{" "}
          </label>
          <input
            id="address"
            onChange={onChangeaddress}
            value={address}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("address")}
          />
          <label htmlFor="email" className="d-block mt-2 ">
            {t("email")}{" "}
          </label>
          <input
            id="email"
            onChange={onChangeemail}
            value={email}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("email")}
          />{" "}
          <label htmlFor="mobile" className="d-block mt-2 ">
            {t("mobile")}{" "}
          </label>
          <input
            id="mobile"
            onChange={onChangemobile}
            value={mobile}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("mobile")}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={handleSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            {t("save")}
          </button>
        </Col>
      </Row>
      {isPress ? (
        loading ? (
          <Spinner animation="border" variant="primary" />
        ) : (
          <h4>{t("done")}</h4>
        )
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default AdminAddContactUsGallery;
