import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBrandById, updateBrands } from "../../redux/actions/brandAction";

import notify from "../useNotification";
import {
  getContactUsGalleryById,
  updateContactUsGallery,
} from "../../redux/actions/contactUsGalleryAction";

const AdminEditContactUsGalleryHook = (id) => {
  const [image, setImage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [contactusStatus, setcontactusStatus] = useState();
  const [address, setaddress] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const run = async () => {
      setLoadingData(true);
      await dispatch(getContactUsGalleryById(id));
      setLoadingData(false);
    };
    run();
  }, []);

  const brand = useSelector(
    (state) => state.contactUsGalleryReducer.contactUsGalleryById
  );

  useEffect(() => {
    if (brand) {
      // console.log('------------------------')
      // console.log(brand)
      // console.log('------------------------')
      setNameAr(brand.city_name_ar);
      setNameEn(brand.city_name_en);
      setmobile(brand.mobile);
      setaddress(brand.address);
      setemail(brand.email);
      setImage(brand.logo);
      setcontactusStatus(brand.status);
    }
  }, [brand]);

  const onChangeNameAr = (e) => {
    e.persist();
    setNameAr(e.target.value);
  };
  const onChangeNameEn = (e) => {
    e.persist();
    setNameEn(e.target.value);
  };
  const onChangecontactusStatus = (e) => {
    e.persist();
    setcontactusStatus(e.target.value);
  };
  const onChangemobile = (e) => {
    setmobile(e.target.value);
  };
  const onChangeemail = (e) => {
    setemail(e.target.value);
  };
  const onChangeaddress = (e) => {
    setaddress(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImage(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };

  //onsubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (nameAr === "" || nameEn === "") {
      notify("من فضلك اكمل البيانات", "warn");
      return;
    }

    const formData = new FormData();
    formData.append("city_name_ar", nameAr);
    formData.append("city_name_en", nameEn);
    formData.append("logo", selectedFile);
    formData.append("status", contactusStatus);
    formData.append("address", address);
    formData.append("mobile", mobile);
    formData.append("email", email);

    setTimeout(async () => {
      setLoading(true);
      // console.log('-----------------')
      // console.log(formData)
      // console.log('-----------------')
      await dispatch(updateContactUsGallery(id, formData));
      setLoading(false);
    }, 1000);
  };

  const brandRes = useSelector(
    (state) => state.contactUsGalleryReducer.updateContactUsGallery
  );

  useEffect(() => {
    if (loading === false) {
      // setTimeout(() => setLoading(true), 1000);
      if (brandRes) {
        if (brandRes.status === 200) {
          notify("تم التعديل بنجاح", "success");
          setTimeout(() => navigate("/admin/contactusgallery"), 1000);
        } else {
          notify("فشل في عملية الاضافه", "error");
        }
      }
    }
  }, [loading]);

  return [
    image,
    nameAr,
    nameEn,
    contactusStatus,
    onChangeNameAr,
    onChangeNameEn,
    onChangecontactusStatus,
    onImageChange,
    handleSubmit,
    email,
    mobile,
    address,
    onChangeaddress,
    onChangeemail,
    onChangemobile,
  ];
};

export default AdminEditContactUsGalleryHook;
