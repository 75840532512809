import React from "react";
import { Button } from "react-bootstrap";
// import AddCatHook from "../../hook/category/AddCatHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
// import { useNavigate } from "react-router-dom";
// import add from "../../Images/add.png";
import { ToastContainer } from "react-toastify";
import BrandPageHook from "../../hook/brand/BrandPageHook";
import AddButton from "../Utilities/AddButton";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
// import { BiCopy } from "react-icons/bi";

import Cookies from "js-cookie";
import AdminContactUsGalleryHook from "../../hook/admin/AdminContactUsGalleryHook";

const AdminContactUsGallery = () => {
  const { t } = useTranslation();

  const [brand, clickHandler, handleShowFun, settingsRes] =
    AdminContactUsGalleryHook();
  // const navigate = useNavigate();

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  let columns = [
    {
      name: t("image"),
      cell: (row) => (
        <img
          src={row.logo}
          alt="conatc usImage"
          width="60px"
          height="60px"
          style={{ padding: "3px", marginLeft: "5px", objectFit: "contain" }}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("city ar"),
      selector: (row) => row["city_name_ar"],
      sortable: true,
    },

    {
      name: t("city en"),
      selector: (row) => row["city_name_en"],
      sortable: true,
    },
    {
      name: t("address"),
      selector: (row) => row["address"],
      sortable: true,
    },
    {
      name: t("mobile"),
      selector: (row) => row["mobile"],
      sortable: true,
    },
    {
      name: t("email"),
      selector: (row) => row["email"],
      sortable: true,
    },

    {
      name: t("conatct us state"),
      selector: (row) => (row.status === true ? t("active") : t("not active")),
      sortable: true,
    },

    {
      name: t("Date created"),
      selector: (row) => formatDate(row.createdAt),
      sortable: true,
    },
    {
      name: t("Modification"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("the status"),
      cell: (row) =>
        row.status === true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
            {t("activation")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: brand,
  };

  return (
    <div>
      <Helmet>
        <title>
          {`  ${t("conatct us gallery")} - ${
            Cookies.get("i18next") === "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>

      <div className="d-flex justify-content-between">
        <div className="admin-content-text">
          {t("conatct us gallery management")}{" "}
        </div>
        <AddButton
          title={t("add conatct us gallery")}
          navigateTo="/admin/addcontactusgallery"
        />
      </div>
      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};

export default AdminContactUsGallery;
