import React from "react";
import { Col, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AdminEditShippingMethod from "../../Components/Admin/AdminEditShippingMethod";
import SideBar from "../../Components/Admin/SideBar";
import EditPaymentMethod from "../../Components/Checkout/EditPaymentMethod";

const AdminEditShippingMethodPage = () => {
    return (
        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-4" style={{ width: '100%' }}>
                <AdminEditShippingMethod />
            </div>
        </div>

    );
};

export default AdminEditShippingMethodPage;

