import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllAds , deleteAds} from "../../redux/actions/adsAction";
import notify from "../useNotification";
import { useTranslation } from "react-i18next";

const AdminViewAdsHook = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [adsId, setAdsId] = useState("");

  const {t} = useTranslation()

  const closeDeleteModal = () => setDeleteModal(false);

  const showDeleteModal = (id) => {
    setAdsId(id);
    setDeleteModal(true);
  };


  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllAds());
  }, []);


  const allAds = useSelector((state) => state.adsReducers.allAds);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  let ads = [];
  if (allAds) ads = allAds;

  
  const deleteAdss = async () => {
    console.log("deleteAds");
    await dispatch(deleteAds(adsId))
    setDeleteModal(false);
    setTimeout(async () => {
      notify(t("Delete successfully"), "success");
     await dispatch(getAllAds());
      setDeleteModal(false);
    }, 1000);
  };

  const clickHandler = (id) => {
    navigate(`/admin/editAds/${id}`);
  };

  return [
    ads,
    clickHandler,
    settingsRes,
    deleteModal,
    closeDeleteModal,
    showDeleteModal,
    deleteAdss,
  ];
};

export default AdminViewAdsHook;
