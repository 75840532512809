import React from "react";
import { Col, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import TermsPolicyAboutHook from "../../hook/termsPolicyAbout/TermsPolicyAboutHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import MultiImageInput from "react-multiple-image-input";

const AdminAbout = () => {
  const { t } = useTranslation();
  const [
    nameAr,
    nameEn,
    onChangeNameAr,
    onChangeNameEn,
    onSubmit,
    settingsRes,
    images,
    setImages,
  ] = TermsPolicyAboutHook(3);

  return (
    <div>
      <Helmet>
        <title>
          {` ${t("about")}  ${
            Cookies.get("i18next") === "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>

      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">{t("about us")} </div>
        <Col>
          <label htmlFor="images" className=" d-block mt-2 ">
            {t("product image")}
          </label>
          <div className="mt-3" id="images">
            <MultiImageInput
              images={images}
              setImages={setImages}
              theme="light"
              max={5}
              // cropConfig={{ crop, ruleOfThirds: true }}
              allowCrop={false}
            />
          </div>
        </Col>
        <Col sm="8">
          <textarea
            value={nameAr}
            onChange={onChangeNameAr}
            className="input-form-area p-2 mt-3"
            rows="9"
            cols="50"
            placeholder={t("About Us (in Arabic)")}
          />
          <textarea
            value={nameEn}
            onChange={onChangeNameEn}
            className="input-form-area p-2 mt-3"
            rows="9"
            cols="50"
            placeholder={t("About Us (in English)")}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            {t("save")}
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminAbout;
