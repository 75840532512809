import React, { useState } from "react";
import { Row, Col, Modal, Button, Table } from "react-bootstrap";
import ProfileHook from "../../hook/user/ProfileHook";
import editicon from "../../Images/edit.png";
import deleteicon from "../../Images/delete.png";

import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

const UserProfile = () => {
  const { t } = useTranslation();
  const [inputs, setInputs] = useState([]);

  const [
    user,
    handleShow,
    handleClose,
    handleSubmit,
    show,
    firstname,
    lastname,
    mobile,
    email,
    onChangeFirstname,
    onChangeLastname,
    onChangeMobile,
    onChangeEmail,
    changePassword,
    oldpassword,
    newPassword,
    confirmPassword,
    onChangeOldPassword,
    onChangeNewPassword,
    onChangeConfirmPassword,
    allShippingMethods,
    onSelectedShippingMethod,
    showTable,
    webSiteData,
    oneShippingMethods,
    valueKeyInput,
    handleChangeInputKey,
    onSubmit,
    settingsRes,
    oneUser,
    wieghtNumber,
    wieghtCost,
    wieghtExtraCost,
    onChangewieghtCost,
    onChangewieghtNumber,
    onChangewieghtExtraCost,
    shippingId,
    showDelete,
    handleShowDelete,
    handleCloseDelete,
    handleDelete,
    confirmPasswordDeleteProfile,
onChangeConfirmPasswordDeleteProfile
  ] = ProfileHook();
  return (
    <div>
      {user.role == "admin" && (
        <Helmet>
          <title>
            {`${t("profile")}- ${
              Cookies.get("i18next") === "ar"
                ? settingsRes.data?.titleAr
                : settingsRes.data?.titleEn
            }`}{" "}
          </title>
        </Helmet>
      )}
      {/* edit profole */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Modification of personal data")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            <input
              value={firstname}
              onChange={onChangeFirstname}
              type="text"
              className="input-form d-block mt-3 px-3"
              placeholder={t("first name")}
            />
            <input
              value={lastname}
              onChange={onChangeLastname}
              type="text"
              className="input-form d-block mt-3 px-3"
              placeholder={t("last name")}
            />
            <input
              value={mobile}
              onChange={onChangeMobile}
              type="text"
              className="input-form d-block  mt-3 px-3"
              placeholder={t("phone")}
            />
            <input
              value={email}
              onChange={onChangeEmail}
              type="text"
              className="input-form d-block  mt-3 px-3"
              placeholder={t("email")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="font" variant="secondary" onClick={handleClose}>
            {t("retreat")}
          </Button>
          <Button className="font" variant="success" onClick={handleSubmit}>
            {t("save")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delete profile */}
      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Confirm deletion")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100">
            {" "}
            <input
              value={confirmPasswordDeleteProfile}
              onChange={onChangeConfirmPasswordDeleteProfile}
              placeholder={t("confirm password")}
              type="password"
              className="user-input my-2 text-center mx-auto w-100"
            />{" "}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="font"
            variant="secondary"
            onClick={handleCloseDelete}
          >
            {t("retreat")}
          </Button>
          <Button className="font" variant="danger" onClick={handleDelete}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="admin-content-text">{t("profile")}</div>
      <div className="user-address-card-2 my-3 px-2">
        <Row className="d-flex justify-content-between pt-2">
          <Col xs="6" className="d-flex">
            <div className="p-2">{t("name")}</div>
            <div className="p-1 item-delete-edit">{`${user.firstname} ${user.lastname}`}</div>
          </Col>
          <Col xs="6" className="d-flex justify-content-end">
            <div className="d-flex mx-2" onClick={handleShow}>
              <img
                alt=""
                className="ms-1 mt-2"
                src={editicon}
                height="17px"
                width="15px"
              />
              <p className="item-delete-edit"> {t("Modify")}</p>
            </div>
            <div className="d-flex " onClick={handleShowDelete}>
              <img
                alt=""
                className="ms-1 mt-2"
                src={deleteicon}
                height="17px"
                width="15px"
              />
              <p className="item-delete-edit"> {t("move")}</p>
            </div>
          </Col>
        </Row>

        <Row className="">
          <Col xs="12" className="d-flex">
            <div className="p-2">{t("phone")}:</div>
            <div className="p-1 item-delete-edit">{user.mobile}</div>
          </Col>
        </Row>
        <Row className="">
          <Col xs="12" className="d-flex">
            <div className="p-2">{t("email")}:</div>
            <div className="p-1 item-delete-edit">{user.email}</div>
          </Col>
        </Row>
        <Row
          className="mt-5 user-address-card-3 p-5"
          style={{
            position: "sticky",
          }}
        >
          <Col xs="10" sm="8" md="6" className="">
            <div className="admin-content-text mt-2">
              {t("change password")}
            </div>
            <label htmlFor="oldPassword" className="d-block mt-4 ">
              {t("old password")}
            </label>
            <input
              id="oldPassword"
              value={oldpassword}
              onChange={onChangeOldPassword}
              type="password"
              className="input-form d-block mt-1 px-3"
              placeholder={t("enter old password")}
            />
            <label htmlFor="newPassword" className="d-block mt-2 ">
              {t("new password")}
            </label>
            <input
              id="newPassword"
              value={newPassword}
              onChange={onChangeNewPassword}
              type="password"
              className="input-form d-block mt-3 px-3"
              placeholder={t("enter new password")}
            />
            <label htmlFor="verifyPassword" className="d-block mt-2 ">
              {t("confirm password")}
            </label>
            <input
              id="verifyPassword"
              value={confirmPassword}
              onChange={onChangeConfirmPassword}
              type="password"
              className="input-form d-block mt-3 px-3"
              placeholder={t("confirm new password")}
            />
            <button
              onClick={changePassword}
              className="btn-save d-inline mt-2 "
              style={{ backgroundColor: webSiteData.PRIMARY_COLOR }}
            >
              {t("save")}
            </button>
          </Col>
          <Col>
            {(user.role == "admin" && webSiteData.MULTI_VENDOR == "false") ||
            user.role == "vendor" ? (
              <div>
                <div className="admin-content-text mt-3">
                  {t("Select a shipping method")}{" "}
                </div>
                <select
                  name="allShippingMethods"
                  onChange={onSelectedShippingMethod}
                  value={shippingId}
                  className="select input-form-area mt-4 px-2 w-75 "
                >
                  <option value="0"> {t("Select a shipping method")}</option>
                  {allShippingMethods
                    ? allShippingMethods.map((el) => {
                        return (
                          <option key={el._id} value={el._id}>
                            {Cookies.get("i18next") == "ar"
                              ? el.nameAr
                              : el.nameEn}
                          </option>
                        );
                      })
                    : null}
                </select>

                {showTable ? (
                  <div style={{ paddingBottom: "35px" }}>
                    <label className="mt-2" htmlFor="wieghtCost">
                      <strong>{t("Shipping method wieghtCost")} </strong>
                    </label>
                    <input
                      id="wieghtCost"
                      value={wieghtCost}
                      onChange={onChangewieghtCost}
                      type="number"
                      className="input-form d-block mt-2 px-3 w-75"
                      placeholder={t("Shipping method cost")}
                    />
                    <label className="mt-2" htmlFor="wieghtNumber">
                      <strong>{t("Shipping method wieghtNumber")} </strong>
                    </label>
                    <input
                      id="wieghtNumber"
                      value={wieghtNumber}
                      onChange={onChangewieghtNumber}
                      type="number"
                      className="input-form d-block mt-2 px-3 w-75"
                      placeholder={t("Shipping method cost")}
                    />
                    <label className="mt-2" htmlFor="wieghtExtraCost">
                      <strong>{t("Shipping method wieghtExtraCost")} </strong>
                    </label>
                    <input
                      id="wieghtExtraCost"
                      value={wieghtExtraCost}
                      onChange={onChangewieghtExtraCost}
                      type="number"
                      className="input-form d-block mt-2 px-3 w-75"
                      placeholder={t("Shipping method cost")}
                    />
                    <Table striped bordered hover className="mt-3 mb-20 w-75 ">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th> {t("Key")}</th>
                          <th>{t("Value")} </th>
                        </tr>
                      </thead>
                      <tbody>
                        {oneShippingMethods?.settingsData?.map((el, key) => (
                          <tr key={key}>
                            <td>{el.count}</td>
                            <td>{el.key}</td>
                            <td>
                              <input
                                name="value"
                                value={
                                  valueKeyInput ? valueKeyInput[key]?.value : ""
                                }
                                onChange={(e) => handleChangeInputKey(e, key)}
                                className="input-form d-block  px-3"
                                type="text"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div className="d-flex justify-content-end w-75">
                      <button
                        onClick={onSubmit}
                        className="btn-save d-inline mt-2 "
                        style={{ backgroundColor: webSiteData.PRIMARY_COLOR }}
                      >
                        {t("save")}
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserProfile;
