import { GET_SETTINGS, EDIT_SETTINGS, GET_WEBSITE_DATA , SEND_NOTIFICATION_TO_ALL_USERS,SENDING_NOTIFICATION,LOADING_SETTING } from "../type";
const inital = {
  settings: [],
  editSettings: [],
  webSiteData: [],
  sendNotification:'',
  sendingNotification:false,
  loadingSetting:false
};

const settingsReducers = (state = inital, action) => {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
        loadingSetting:false
      };
    case EDIT_SETTINGS:
      return {
        ...state,
        editSettings: action.payload,
      };
    case GET_WEBSITE_DATA:
      return {
        ...state,
        webSiteData: action.payload,
      };
      case SEND_NOTIFICATION_TO_ALL_USERS:
        return{
          ...state,
          sendNotification:action.payload,
          sendingNotification:false
        }
      case SENDING_NOTIFICATION:
        return{
          ...state,
          sendingNotification:true
        }
      case LOADING_SETTING:
        return{
          ...state,
          loadingSetting:true
        }
    default:
      return state;
  }
};
export default settingsReducers;
