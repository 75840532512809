import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editData, getData } from "../../redux/actions/termsPolicyAboutAction";
import notify from "../useNotification";

const TermsPolicyAboutHook = (type) => {
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [images, setImages] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingEdit, setLoadingEdit] = useState(true);

  const dispatch = useDispatch();

  const res = useSelector((state) => state.termsPolicyAboutReducer.data);

  useEffect(() => {
    const get = async () => {
      window.scrollTo(0, 0);
      setLoading(true);
      await dispatch(getData(type));
      setLoading(false);
    };
    get();
  }, [dispatch, type]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [type]);

  const onChangeNameAr = (e) => {
    e.persist();
    setNameAr(e.target.value);
  };
  const onChangeNameEn = (e) => {
    e.persist();
    setNameEn(e.target.value);
  };

  useEffect(() => {
    if (loading === false) {
      if (res && res.data) {
        setNameAr(res.data.nameAr);
        setNameEn(res.data.nameEn);
        if (res.data.images) setImages(res.data.images);
        else setImages([]);
      }
    }
  }, [loading]);

  const editRes = useSelector(
    (state) => state.termsPolicyAboutReducer.editData
  );
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  //convert url to file
  const convertURLtoFile = async (url) => {
    const response = await fetch(url, { mode: "cors" });
    const data = await response.blob();
    const ext = url.split(".").pop();
    const filename = url.split("/").pop();
    const metadata = { type: `image/${ext}` };
    return new File([data], Math.random(), metadata);
  };

  const onSubmit = async () => {
    let itemImages = [];

    Array.from(Array(Object.keys(images).length).keys()).map((item, index) => {
      if (images[index].length <= 1000) {
        // console.log("dd");
        convertURLtoFile(images[index]).then((val) => itemImages.push(val));
      } else {
        // console.log("ee");
        itemImages.push(dataURLtoFile(images[index], Math.random() + ".png"));
      }
    });

    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    console.log(itemImages);
    itemImages.map((item) => formData.append("images", item));

    setLoadingEdit(true);

    await dispatch(editData(type, formData));
    setLoadingEdit(false);
  };

  useEffect(() => {
    if (loadingEdit === false) {
      console.log(editRes);
      if (editRes && editRes.data.success === true) {
        notify("تم التعديل بنجاح", "success");
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loadingEdit]);

  return [
    nameAr,
    nameEn,
    onChangeNameAr,
    onChangeNameEn,
    onSubmit,
    settingsRes,
    images,
    setImages,
  ];
};

export default TermsPolicyAboutHook;
