import { ALL_USERS, GET_ONE_USER, UPDATE_USER, ADD_USER_BY_ADMIN, GET_ONE_USER_TOKEN, ALL_VENDORS,UPDATE_PASSWORD_BY_ADMIN,UPDATING_PASSWORD_BY_ADMIN } from "../type";
const inital = {
  allUser: [],
  oneUser: [],
  oneUserToken: [],
  updateUser: [],
  addUser: [],
  allVendors:[],
  updatePasswordByAdmin:"",
  updatingPassword:false

};

const usersReducers = (state = inital, action) => {
  switch (action.type) {
    case ALL_USERS:
      return {
        ...state,
        allUser: action.payload,
      };
    case GET_ONE_USER:
      return {
        ...state,
        oneUser: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        updateUser: action.payload,
      };
    case GET_ONE_USER_TOKEN:
      return {
        ...state,
        oneUserToken: action.payload,
      };

    case ADD_USER_BY_ADMIN:
      return {
        ...state,
        addUser: action.payload
      }
      case ALL_VENDORS:
        return {
          ...state,
          allVendors: action.payload
        }
      case UPDATE_PASSWORD_BY_ADMIN:
        return{
          ...state,
          updatePasswordByAdmin:action.payload,
          updatingPassword:false
        }
     case UPDATING_PASSWORD_BY_ADMIN:
      return{
        ...state,
        updatingPassword:true
      } 
    default:
      return state;
  }
};

export default usersReducers;
