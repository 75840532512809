import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategory } from "../redux/actions/categoryAction";
import { getSettings } from "../redux/actions/settingsAcions";

const FooterHook = () => {
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [phone2, setPhone2] = useState("");
  const [phone3, setPhone3] = useState("");
  const [address, setAdress] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [commercialRegister, setCommercialRegister] = useState("");
  const [titleAr, setTitleAr] = useState("");
  const [titleEn, setTitleEn] = useState("");
  const[googlePlayLink,setGooglePlayLink]=useState("");
  const[appleStoreLink,setAppleStoreLink]=useState("");
  const[whatsApp,setWhatsApp]=useState('');


  const dispatch = useDispatch();


  useEffect(() => {

    dispatch(getAllCategory(6));
    dispatch(getSettings())

  }, []);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const category = useSelector((state) => state.allCategory.category);



  useEffect(() => {

    if (settingsRes && settingsRes.data) {
      setFacebook(settingsRes.data.facebook);
      setInstagram(settingsRes.data.instagram);
      setTwitter(settingsRes.data.twitter);
      setPhone(settingsRes.data.phone);
      setEmail(settingsRes.data.email);
      setPhone2(settingsRes.data.phone2);
      setPhone3(settingsRes.data.phone3);
      setAdress(settingsRes.data.address);
      setVatNumber(settingsRes.data.vatNumber);
      setCommercialRegister(settingsRes.data.commercialRegister);
      setTitleAr(settingsRes.data.titleAr);
      setTitleEn(settingsRes.data.titleEn);
      setGooglePlayLink(settingsRes?.data?.googlePlayLink ==="undefined" ? false : settingsRes?.data?.googlePlayLink);
      setAppleStoreLink(settingsRes?.data?.appleStoreLink ==="undefined" ? false : settingsRes?.data?.appleStoreLink);
      setWhatsApp(settingsRes?.data?.whatsApp ==="undefined" ? false : settingsRes?.data?.whatsApp);
    }
  }, [settingsRes]);

  return [
    facebook,
    instagram,
    twitter,
    phone,
    phone2,
    phone3,
    email,
    address,
    category,
    vatNumber,
    commercialRegister,
    titleAr,
    titleEn,
    googlePlayLink,
    appleStoreLink,
    whatsApp
  ];
};

export default FooterHook;
