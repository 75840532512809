import { GENERATE_HYPERPAY_CHECKOUT ,ISLOADING_HYPERPAY , GET_HYPERPAY_STATUS } from "../type";


const initial={
    checkOut:{},
    status:{},
    isLoading:false
};

const HyperPayReducer=(state=initial , action)=>{
    switch (action.type) {
        case GENERATE_HYPERPAY_CHECKOUT:
            return{
                ...state,
                checkOut:action.payload,
                isLoading:false
            }
        case GET_HYPERPAY_STATUS:
            return{
                ...state,
                status:action.payload,
                isLoading:false
            }

        case ISLOADING_HYPERPAY:
            return{
                ...state,
                isLoading:true
            }
        
    
        default:
            return state;
    }
}

export default HyperPayReducer;