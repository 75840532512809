import React, { useRef } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AddCouponHook from "../../hook/coupon/AddCouponHook";
import AdminCouponCard from "./AdminCouponCard";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import add from "../../Images/add.png";
import { useNavigate } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
const AdminCoupons = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dateRef = useRef();
  const [
    couponName,
    couponDate,
    couponValue,
    couponType,
    onChangeCouponName,
    onChangeCouponDate,
    onChangeCouponValue,
    onChangeCouponType,
    onSubmit,
    coupons,
    clickHandler,
    show,
    handleShow,
    handleClose,
    handleDelete,
    settingsRes
  ] = AddCouponHook();


  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  //${Cookies.get("i18next")=="ar"?item.nameAr:item.nameEn}

  let nuwCoupons = coupons.map((copon) => {
    var currencytype = Cookies.get("i18next") === "ar" ? settingsRes.data.defaultCurrancy.ar : settingsRes.data.defaultCurrancy.en
    return {
      ...copon,
      endDate: formatDate(copon.endDate),
      value: copon.type == 1 ? copon.value + currencytype : copon.value + " % "
    }
  })


  let columns = [
    {
      name: t("copon name"),
      selector: 'title',
      sortable: true,
    },
    {
      name: t("experation date"),
      selector: 'endDate',
      sortable: true,
    },
    {
      name: t("Discount rate"),
      selector: 'value',
      sortable: true,
    },
    {
      name: t("Modification"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("delete"),
      cell: (row) => (
        <Button
          variant="danger"
          onClick={() => handleShow(row._id)}
          id={row._id}
        >
          {t("delete")}
          <i className="fa fa-trash mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

  ];

  const tableData = {
    columns,
    data: nuwCoupons,
  };






  return (
    <div>


      <Helmet>
        <title>{`  ${t("Discount Coupons")}  - ${settingsRes.data?.titleAr}`} </title>
      </Helmet>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("Confirm deletion")} </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">{t("Are you sure about the deletion process?")}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="font" variant="secondary" onClick={handleClose}>
            {t("retreat")}
          </Button>
          <Button className="font" variant="danger" onClick={handleDelete}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>



      <div className="d-flex justify-content-between">
        <div className="admin-content-text"> {t("Coupon Management")}</div>
        <AddButton title={t("add copon")} navigateTo='/admin/addcoupon' />

      </div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>




      <Row>
        <Col sm="8">
          {coupons ? (
            ''
          ) : (
            <h6>{t("There are no coupons currently")}</h6>
          )}
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminCoupons;
