import SideBar from "../../Components/Admin/SideBar"
import EditOffer from "../../Components/Offers/EditOffer"
import VendorSideBar from "../../Components/Vendor/VendorSideBar"

const EditOfferPage = () => {
  const user=JSON.parse(localStorage.getItem("user"));

  console.log(user);

    return (
        <div className="d-flex justify-content-start">
        <div>
          {
            user.role=="admin"? <SideBar /> : <VendorSideBar />
          }
          
        </div>
        <div className="p-4" style={{ width: "100%" }}>
        <EditOffer/>
        </div>
      </div>
    )

}

export default EditOfferPage