import React from "react";
import "./ButtonsGroup.css";
import CatPageHook from "../../hook/category/CatPageHook";

const ButtonsGroup = ({ category, onCategoryClick }) => {
  return (
    <div className="buttons-group">
      <button className="filter-button" onClick={() => onCategoryClick(null)}>
        الجميع
      </button>
      {category
        ? category.slice(0, 5).map((el) => (
            <button
              key={el._id}
              className="filter-button"
              onClick={() => onCategoryClick(el._id)}
            >
              {el.nameAr}
            </button>
          ))
        : null}
    </div>
  );
};

export default ButtonsGroup;
