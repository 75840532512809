import React from "react";
import { Col, Row } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import TermsPolicyAboutHook from "../../hook/termsPolicyAbout/TermsPolicyAboutHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

const AdminTerms = () => {
  const { t } = useTranslation();
  const [nameAr, nameEn, onChangeNameAr, onChangeNameEn, onSubmit, settingsRes] =
    TermsPolicyAboutHook(1);

  return (
    <div>

      <Helmet>
        <title>{`${t("Terms and Conditions")} - ${Cookies.get("i18next") === "ar" ? settingsRes.data?.titleAr : settingsRes.data?.titleEn}`} </title>
      </Helmet>


      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">{t("Terms and Conditions")}</div>
        <Col sm="8">
          <textarea
            value={nameAr}
            onChange={onChangeNameAr}
            className="input-form-area p-2 mt-3"
            rows="9"
            cols="50"
            placeholder={t("terms in arabic")}
          />
          <textarea
            value={nameEn}
            onChange={onChangeNameEn}
            className="input-form-area p-2 mt-3"
            rows="9"
            cols="50"
            placeholder={t("terms in english")}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button onClick={onSubmit} className="btn-save d-inline mt-2 " style={{ backgroundColor: settingsRes?.data?.primaryColor }}>
            {t("save")}
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};
export default AdminTerms;
