import React from "react";
import BrandCard from "./BrandCard";
import { Container, Row, Spinner } from "react-bootstrap";
import { t } from "i18next";
import Cookies from "js-cookie";
import BrandPageHook from "../../hook/brand/BrandPageHook";
import SecondNavGallery from "../Gallary/SecondNavGallery";
import { useSelector } from "react-redux";
import "./Artists.css";
import { Link } from "react-router-dom";

const BrandContainer = ({ data, loading }) => {
  const settingsRes = useSelector((state) => state.settingsReducers.settings);

  return (
    <div>
      {settingsRes.data?.gallaryTheme === false ? null : <SecondNavGallery />}

      <Container>
        {settingsRes.data?.gallaryTheme === false ? (
          <>
            <div className="admin-content-text mt-2 ">{t("All brands")} </div>
            <Row className="my-2 d-flex justify-content-center">
              {/* <BrandCard img={brand1} /> */}

              {
                data ? (
                  data.map((el) => {
                    return <BrandCard id={el._id} key={el._id} img={el.logo} />;
                  })
                ) : (
                  <h3 className="text-center">{t("no brands")} </h3>
                )
                // ) : (
                //   <Spinner animation="border" variant="primary" />
              }
            </Row>
          </>
        ) : (
          <div className="artists-container">
            <div className="artists-title">{t("الفنانين")} </div>
            <div className="artists-grid">
              {data &&
                data.map((artist, index) => (
                  <Link
                    to={`/products/artGallery/brand/${artist._id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div key={index} className="artist-card">
                      <div className="artist-image-wrapper">
                        <img
                          src={artist.logo}
                          alt={"name"}
                          className="artist-image"
                        />
                        <p className="artist-name-overlay">{artist.nameAr}</p>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default BrandContainer;
