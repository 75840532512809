import React from "react";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
import {  useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import AdminDropShippingHook from "../../hook/admin/AdminDropShippingHook";
import { Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


export const AdminDropShippings = () => {
  const { t } = useTranslation();
  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const [dropShippingList  , handleShowFun , clickHandler , updating , updateProductsHandler] = AdminDropShippingHook();
  


  const navigate = useNavigate();

  // console.log('dropShippingList')
  // console.log(dropShippingList)

  const columns = [
    {
      name: t("name Ar"),
      selector: (row) => row["nameAr"],
      sortable: true,
    },
    {
      name: t("name En"),
      selector: (row) => row["nameEn"],
      sortable: true,
    },
    {
      name: t("Profit Ratio"),
      selector: (row) => row["profitRatio"],
      sortable: true,
    },
    {
      name: t("Conversion factor"),
      selector: (row) => row["currencyFactor"],
      sortable: true,
    },
    {
      name:t("updateProducts"),
      cell:(row)=>(
        <Button
        variant="primary"
        onClick={updateProductsHandler}
        id={row._id}
        >
          { updating ? <Spinner /> :t("updateProducts")}
        </Button>
      )
    },
    {
        name: t("Modification"),
        cell: (row) => (
          <Button
            variant="secondary"
            onClick={() =>navigate(`/admin/editDropShipppings/${row._id}`)}
            id={row._id}
          >
            {t("Modify")}
            <i className="fa fa-pencil-square-o mx-1" />
          </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
     
      {
        name: t("the status"),
        cell: (row) =>
          row.status === true ? (
            <Button
              variant="danger"
              onClick={() => {
                handleShowFun(row._id, false);
              }}
              id={row._id}
            >
              {t("block")}
              <i className="fa fa-lock mx-1" />
            </Button>
          ) : (
            <Button
              variant="success"
              onClick={() => {
                handleShowFun(row._id, true);
              }}
              id={row._id}
            >
              {t("active")}
              <i className="fa fa-unlock mx-1" />
            </Button>
          ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
  ];

  const tableData = {
    columns,
    data: dropShippingList,
  };

  return (
    <div>
      <Helmet>
        <title>
          {` ${t("DropShippings")} -  ${
            Cookies.get("i18next") === "ar"
              ? settingsRes.data?.titleAr
              : settingsRes.data?.titleEn
          }`}{" "}
        </title>
      </Helmet>

      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("DropShippings")} </div>
      </div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};
