import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import EditBrandHook from "../../hook/brand/EditBrandHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import AdminEditContactUsGalleryHook from "../../hook/admin/AdminEditContactUsGalleryHook";
const AdminEditContactUsGallery = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [
    image,
    nameAr,
    nameEn,
    contactusStatus,
    onChangeNameAr,
    onChangeNameEn,
    onChangecontactusStatus,
    onImageChange,
    handleSubmit,
    email,
    mobile,
    address,
    onChangeaddress,
    onChangeemail,
    onChangemobile,
  ] = AdminEditContactUsGalleryHook(id);
  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4"></div>
        <Col sm="8">
          <label htmlFor="image" className="d-block my-2">
            {" "}
            {t("image")}{" "}
          </label>
          {/* <img src={avatar} alt="" height="100px" width="120px" /> */}
          <div>
            <label htmlFor="upload-photo">
              <img
                id="image"
                src={image}
                alt="fzx"
                height="100px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo"
              onChange={onImageChange}
              id="upload-photo"
            />
          </div>
          <label htmlFor="caontactAr" className="d-block mt-3">
            {t("contact us name in arabic")}
          </label>
          <input
            id="caontactAr"
            onChange={onChangeNameAr}
            value={nameAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("contact us name in arabic")}
          />
          <label htmlFor="caontactEn" className="d-block mt-2">
            {t("contact us name in english")}
          </label>
          <input
            id="caontactEn"
            onChange={onChangeNameEn}
            value={nameEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("contact us name in english")}
          />
          <label htmlFor="status" className="d-block mt-2">
            {" "}
            {t("contactus state")}{" "}
          </label>
          <select
            value={contactusStatus}
            onChange={onChangecontactusStatus}
            name="status"
            id="status"
            className="select input-form-area mt-2 px-3 "
          >
            <option value="0"> {t("the status")}</option>
            <option value="true">{t("active")}</option>
            <option value="false">{t("not active")}</option>
          </select>
          <label htmlFor="address" className="d-block mt-2 ">
            {t("address")}{" "}
          </label>
          <input
            id="address"
            onChange={onChangeaddress}
            value={address}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("address")}
          />
          <label htmlFor="email" className="d-block mt-2 ">
            {t("email")}{" "}
          </label>
          <input
            id="email"
            onChange={onChangeemail}
            value={email}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("email")}
          />{" "}
          <label htmlFor="mobile" className="d-block mt-2 ">
            {t("mobile")}{" "}
          </label>
          <input
            id="mobile"
            onChange={onChangemobile}
            value={mobile}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("mobile")}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={handleSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: settingsRes?.data?.primaryColor }}
          >
            {t("save")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminEditContactUsGallery;
