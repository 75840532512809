import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import notify from "../useNotification";
import {addContact} from "../../redux/actions/contactUsAction"


const AddTicketHook = () => {

    const [name,setName] = useState("");
    const [mobile,setMobile] = useState("");
    const [msgtitle,setMsgtitle] = useState("");
    const [msgtext,setMsgtext] = useState("")
    const [contactType,setContactType] = useState("")
    const [loading,setLoading] = useState(true);


    useEffect(()=>{
        window.scrollTo(0,0)
    },[])

    const dispatch = useDispatch()

const onChangeName = (e) => {
    setName(e.target.value)
}
const onChangeMobile = (e) => {
    setMobile(e.target.value)
}
const onChangeMsgTitle = (e) => {
    setMsgtitle(e.target.value)
}

const onChangeMsgText = (e) => {
    setMsgtext(e.target.value)
}
const onChangeContactType = (e) => {
    setContactType(e.target.value)
}


const onSubmit = async () => {

    if (
        name === "" ||
        contactType == '' ||
        contactType == '0' ||
        msgtext === "" ||
        msgtitle === ""
      ) {
        notify("من فضلك اكمل البيانات", "error");
        return;
      }
      setLoading(true)
      await dispatch(addContact({
        name:name,
        mobile:mobile,
        msgtitle:msgtitle,
        msgtext:msgtext,
        contactType:contactType
      }))

      setLoading(false)

}

const res = useSelector((state)=> state.contactUsReducer.addTicket)
useEffect(()=> {

    if(loading === false) {
        if(res) {
            setName("")
            setMobile("")
            setMsgtext("")
            setMsgtitle("")
            notify("تم الإرسال بنجاح", "success");
    
        }
    }else {

    }
},[loading])


return [
    name,
    mobile,
    msgtitle,
    msgtext,
    contactType,
    onChangeName,
    onChangeMobile,
    onChangeMsgTitle,
    onChangeMsgText,
    onChangeContactType,
    onSubmit
]


}

export default AddTicketHook