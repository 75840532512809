import { GET_ALL_PAYMENT_METHOD } from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useInsertData } from "../../hooks/useInsertData";
import { ADD_PAYMENT_METHOD } from "../type";
import { GET_ONE_PAYMENT_METHOD } from "../type";
import { EDIT_PAYMENT_METHOD } from "../type";
import { useUpdateData } from "../../hooks/useUpdateData";

import { PAYMOB } from "../type";

// Add Payment Method
export const addPaymentMethod = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/payment`, body);
    dispatch({
      type: ADD_PAYMENT_METHOD,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_PAYMENT_METHOD,
      payload: error,
    });
  }
};

//Get All Payment Methods by user
export const getPaymentMethods = () => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/payment`);

    dispatch({
      type: GET_ALL_PAYMENT_METHOD,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PAYMENT_METHOD,
      payload: error.response,
    });
  }
};
//Get All Payment Methods by Admin
export const getPaymentMethodsByAdmin = () => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/payment/admin`);

    dispatch({
      type: GET_ALL_PAYMENT_METHOD,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_PAYMENT_METHOD,
      payload: error.response,
    });
  }
};

// Get One Payment Method
export const getOnePaymentMethod = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/payment/${id}`);
    dispatch({
      type: GET_ONE_PAYMENT_METHOD,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_PAYMENT_METHOD,
      payload: error.response,
    });
  }
};

// Edit Payment Method
export const editPaymentMethod = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/payment/${id}`, body);

    dispatch({
      type: EDIT_PAYMENT_METHOD,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: EDIT_PAYMENT_METHOD,
      payload: error.response,
    });
  }
};

//pay with paymob
export const payWithPayMob = (body) => async (dispatch) => {
  try {
    const response = await useInsertData(`/api/v1/paymob`, body);
    //const res = await baseURL.post(url, params, config);

    //console.log(response);

    dispatch({
      type: PAYMOB,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: PAYMOB,
      payload: error,
    });
  }
};
