import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCategories,
  getAllCategoryPage,
} from "../../redux/actions/categoryAction";
import { useLocation } from "react-router-dom";
import { subCategoryByCatId } from "../../redux/actions/subCategoryAction";

const SubCategoryHook = (id) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  useEffect(() => {
    setLoadingSpinner(true);
    dispatch(subCategoryByCatId(id, true));
    setLoadingSpinner(false);

    window.scrollTo(0, 0);
  }, [dispatch, location.pathname]);
  const category = useSelector((state) => state.subCategory.subCategoryByCat);
  const loading = useSelector((state) => state.allCategory.loading);
  // let pageCount = 0;
  // if (category.numberOfPages) pageCount = category.numberOfPages;

  // const getPage = (page) => {
  //   dispatch(getAllCategoryPage(page));
  // };

  const colors = [
    "#FFD3E8",
    "#F4DBA5",
    "#55CFDF",
    "#FF6262",
    "#0034FF",
    "#FFD3E8",
  ];

  return [category, loading, loadingSpinner, colors];
};

export default SubCategoryHook;
